import * as GraphQL from "../../graphql"
import { ListForm } from "../../state/listModalFormSlice"
import {
  AUDIENCE_DISPLAY_TOGGLES,
  CONTENT_DISPLAY_TOGGLES,
  INSIGHTS_DISPLAY_TOGGLES,
  LIST_DISPLAY_TOGGLES,
} from "../../util/constant"
import { Scope } from "../../util/types"

const { Network } = GraphQL

interface IDefaultInactiveTogglesOnNetwork {
  [Network.Instagram]: string[],
  [Network.Facebook]: string[],
  [Network.Tiktok]: string[],
  [Network.Snapchat]: string[],
  [Network.Youtube]: string[],
  [Network.Twitter]: string[],
}

export const defaultInactiveTogglesOnNetwork: IDefaultInactiveTogglesOnNetwork = {
  [Network.Instagram]: [
    INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
    INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED,
    CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
  ],
  [Network.Facebook]: [
    INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED,
  ],
  [Network.Tiktok]: [],
  [Network.Snapchat]: [],
  [Network.Youtube]: [
    INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED,
  ],
  [Network.Twitter]: [
    INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED,
  ],
}

export interface ToggleOptions {
  title: string;
  detail: string;
  flag: string;
  /**
   * List of scopes required to display this toggle.
   */
  scopes: string[];
  /**
   * List of networks that this toggle is not valid for.
   * If the account is from one of these networks, the toggle will be filtered from the options.
   */
  invalidNetworks: GraphQL.Network[];
}

export const insightOptionList: ToggleOptions[] = [
  {
    title: "Engagement Rate Over Time",
    detail: "Display the Engagement Over Time graph on individual accounts.",
    flag: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME,
    scopes: [],
    invalidNetworks: [],
  },
  {
    title: "Engagement by Post Type Organic vs Sponsored",
    detail: "Display Engagement by Post Type Organic vs Sponsored for individual accounts.",
    flag: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
    scopes: [ Scope.ENGAGEMENT_BY_POST_TYPE ],
    invalidNetworks: [],
  },
  {
    title: "Engagement by Post Type Reel vs Non-Reel",
    detail: "Display Engagement by Post Type Reel vs Non-Reel for individual accounts.",
    flag: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
    scopes: [ Scope.ENGAGEMENT_BY_POST_TYPE ],
    invalidNetworks: [],
  },
  {
    title: "Engagements Heat Map",
    detail: "Display the Engagement Heatmap on individual accounts.",
    flag: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP,
    scopes: [],
    invalidNetworks: [],
  },
  {
    title: "Recent Media And AI Image Analysis",
    detail: "Display the Recent Media and AI Image Analysis for an individual account.",
    flag: INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS,
    scopes: [],
    invalidNetworks: [],
  },
  {
    title: "Recent Media Stories",
    detail: "Display Recent Media Stories for an individual account.",
    flag: INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
    scopes: [],
    invalidNetworks: [ Network.Tiktok, Network.Twitter, Network.Facebook, Network.Snapchat, Network.Youtube ],
  },
  {
    title: "Brands Mentioned And Brands Worked With",
    detail: "Display the brands that have been mentioned by an individual account.",
    flag: INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
    scopes: [],
    invalidNetworks: [ Network.Tiktok, Network.Snapchat ],
  },
  // NOTE: Temporarily Disabling option while feature is down
  // {
  //   title: "Brands Detected",
  //   detail: "Display the brands that have been detected in posts by an individual account.",
  //   flag: INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED,
  //   scopes: [ Scope.FEATURE_SEARCH_BRAND_LOGO ],
  //   invalidNetworks: [ Network.Tiktok, Network.Snapchat ],
  // },
  {
    title: "PI Traits",
    detail: "Display Personality Traits for individual accounts.",
    flag: INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
    scopes: [],
    invalidNetworks: [ Network.Snapchat ],
  },
]

export const audienceOptionList: ToggleOptions[] = [
  {
    title: "Followers Over Time",
    detail: "Display the followers over time graph on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
    scopes: [],
    invalidNetworks: [],
  },
  {
    title: "Audience Quality",
    detail: "Display audience quality data for individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.QUALITY,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_QUALITY ],
    invalidNetworks: [ Network.Tiktok, Network.Snapchat ],
  },
  {
    title: "Gender",
    detail: "Display audience gender percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.GENDER,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Family Status",
    detail: "Display family status percentages for individual account audiences.",
    flag: AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Age Range",
    detail: "Display audience age range percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Income Range",
    detail: "Display audience income levels as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Education Level",
    detail: "Display audience education levels as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Ethnicities",
    detail: "Display the top ethnicities of audiences on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Languages",
    detail: "Display the top languages of audiences on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.LANGUAGES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Religions",
    detail: "Display the top religions of audiences on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Countries",
    detail: "Display the top countries of audiences as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "States",
    detail: "Display the top US states of audiences as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.STATES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Cities",
    detail: "Display the top cities of audiences as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.CITIES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Occupations",
    detail: "Display the top occupations of audiences as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Industries",
    detail: "Display the top industries of audiences as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Employers",
    detail: "Display the top employers of audiences as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Universities",
    detail: "Display the top universities of audiences as percentages on individual accounts.",
    flag: AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
  {
    title: "Affinities",
    detail: "Affinities description",
    flag: AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
    scopes: [ Scope.FEATURE_DISPLAY_AUDIENCE_DATA ],
    invalidNetworks: [ Network.Snapchat ],
  },
]

export const contentOptionList: ToggleOptions[] = [
  {
    title: "Top Posts",
    detail: "Display top posts for each individual account.",
    flag: CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
    scopes: [],
    invalidNetworks: [ ],
  },
  {
    title: "Relevant Posts",
    detail: "Relevant Posts description",
    flag: CONTENT_DISPLAY_TOGGLES.KEYWORDS,
    scopes: [],
    invalidNetworks: [ ],
  },
  {
    title: "Post Impressions",
    detail: "Display impression data for posts.",
    flag: CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
    scopes: [],
    invalidNetworks: [ Network.Tiktok, Network.Twitter, Network.Facebook, Network.Snapchat, Network.Youtube ],
  },
]

export const engagementToggles = [
  LIST_DISPLAY_TOGGLES.ENG_SCORE,
  LIST_DISPLAY_TOGGLES.ENG_RATE,
  LIST_DISPLAY_TOGGLES.AUDIENCE_QUALITY,
  LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
  LIST_DISPLAY_TOGGLES.ISCORE,
  LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
  LIST_DISPLAY_TOGGLES.NO_SCORE,
]

export interface CheckboxOption<T> {
  label: string
  flag: T
}

export const genderOptions = [
  {
    label: "Female",
    flag: GraphQL.Sex.Female,
  },
  {
    label: "Male",
    flag: GraphQL.Sex.Male,
  },
]

export const ethnictyOptions = [
  {
    label: "African American",
    flag: GraphQL.Ethnicity.AfricanAmerican,
  },
  {
    label: "Asian / Pacific Islander",
    flag: GraphQL.Ethnicity.AsianPacificIslander,
  },
  {
    label: "Hispanic / Latino",
    flag: GraphQL.Ethnicity.HispanicLatino,
  },
  {
    label: "White / Caucasian",
    flag: GraphQL.Ethnicity.WhiteCaucasian,
  },
]

export const familyOptions = [
  {
    label: "Married",
    flag: GraphQL.Family.Married,
  },
  {
    label: "Single",
    flag: GraphQL.Family.Single,
  },
  {
    label: "Parent",
    flag: GraphQL.Family.Parent,
  },
]

export const religionOptions = [
  {
    label: "Christian",
    flag: GraphQL.Religion.Christian,
  },
  {
    label: "Jewish",
    flag: GraphQL.Religion.Jewish,
  },
  {
    label: "Muslim",
    flag: GraphQL.Religion.Muslim,
  },
]

export const incomeOptions = [
  {
    label: "$0 - $9,999",
    flag: GraphQL.IncomeBrackets.Under_10000,
  },
  {
    label: "$10,000 - $19,999",
    flag: GraphQL.IncomeBrackets["10000_19999"],
  },
  {
    label: "$20,000 - $29,999",
    flag: GraphQL.IncomeBrackets["20000_29999"],
  },
  {
    label: "$30,000 - $39,999",
    flag: GraphQL.IncomeBrackets["30000_39999"],
  },
  {
    label: "$40,000 - $49,999",
    flag: GraphQL.IncomeBrackets["40000_49999"],
  },
  {
    label: "$50,000 - $74,999",
    flag: GraphQL.IncomeBrackets["50000_74999"],
  },
  {
    label: "$75,000 - $99,999",
    flag: GraphQL.IncomeBrackets["75000_100000"],
  },
  {
    label: "$100,000+",
    flag: GraphQL.IncomeBrackets["100000Above"],
  },
]

type SetStateAction<T> = (value: T[]) => void

export const handleCheckboxUpdate = <T>(
  option: CheckboxOption<T>,
  currentState: T[],
  setStateAction: SetStateAction<T>,
) => {
  const updatedState = [ ...currentState ]
  if (updatedState.includes(option.flag)) {
    const index = updatedState.indexOf(option.flag)
    updatedState.splice(index, 1)
  } else {
    updatedState.push(option.flag)
  }

  setStateAction(updatedState)
}

export function populateListForm(
  seedFormData: GraphQL.SuggestionList,
  formInitState: ListForm,
) {
  const interformInitState = { ...formInitState }
  interformInitState.affinities = seedFormData.affinities.map((affinity) => (affinity.code))
  interformInitState.avatarId = seedFormData?.avatar?.id || null
  interformInitState.categories = []
  interformInitState.clientIds = seedFormData.clients as any
  interformInitState.contextualRelevancy = seedFormData.contextualRelevancy
  interformInitState.description = seedFormData.description
  interformInitState.keywords = seedFormData.keywords.map((keyword) => (keyword.name))
  interformInitState.imageTags = seedFormData.imageTags.map((imageTag) => (imageTag.name))
  interformInitState.maxAge = seedFormData.maxAge
  interformInitState.minAge = seedFormData.minAge
  interformInitState.name = seedFormData.name
  interformInitState.network = seedFormData.network
  interformInitState.isPublic = seedFormData.public
  interformInitState.sexes = seedFormData.sexes
  interformInitState.subscribedUserIds = seedFormData.subscribedUsers.map((user) => (user.id))
  interformInitState.suggestionAccessCode = (() => {
    if (seedFormData.suggestionAccessCode && seedFormData.suggestionAccessCode.accessCode) {
      const { accessCode, suggestionAccessCodeRestriction } = seedFormData.suggestionAccessCode
      return {
        accessCode,
        suggestionAccessCodeRestriction,
      }
    }
    return null
  })()
  interformInitState.suggestionListMode = seedFormData.suggestionListMode
  interformInitState.suggestionListVerticalIds = seedFormData.verticals?.map((cat) => cat.id)
  interformInitState.toggles = seedFormData.toggles?.map((toggle) => toggle.name)
  interformInitState.vetting = seedFormData.vetting
  interformInitState.vettingSubscriptionUserIds = seedFormData.subscribedVettingUsers.map((user) => user.id)
  interformInitState.demographicScoreMinAge = seedFormData.demographicScoreMinAge
  interformInitState.demographicScoreMaxAge = seedFormData.demographicScoreMaxAge
  interformInitState.demographicScoreGender = seedFormData.demographicScoreGender
  interformInitState.demographicScoreEthnicity = seedFormData.demographicScoreEthnicity
  interformInitState.demographicScoreFamily = seedFormData.demographicScoreFamily
  interformInitState.demographicScoreReligion = seedFormData.demographicScoreReligion
  interformInitState.demographicScoreIncome = seedFormData.demographicScoreIncome
  interformInitState.demographicScoreLocations = seedFormData.demographicScoreLocations.map((location) => (location.code))
  interformInitState.demographicScoreOccupations = seedFormData.demographicScoreOccupations.map((occupation) => (occupation.code))
  interformInitState.demographicScoreAffinities = seedFormData.demographicScoreAffinities.map((affinity) => (affinity.code))

  return interformInitState
}

export const filterOptions = (options: ToggleOptions[], network: GraphQL.Network | null, scopes: string[]) => {
  // filter by required scopes and invalid networks
  const newOptions = options.filter((option) => option.scopes.every((scope) => scopes.includes(scope)))
    .filter((option) => option.invalidNetworks.every((_network) => network !== _network))
  return newOptions
}
