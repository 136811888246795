import React from "react"
import { useTranslation } from "react-i18next"
import "./customer-user-header.sass"
import { EditOutlined } from "@mui/icons-material"
import dayjs from "dayjs"
import { IconButton } from "@mui/material"
import { CustomerUserFragment } from "../../graphql"
import Avatar from "../Avatar"
import Tooltip from "../Tooltip"
import { MONTH_DAY_ABBR_FORMAT } from "../../util/constant"

type Props = {
  user: CustomerUserFragment
}

export default function CustomerUserHeader({ user }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerHeader" })

  return (
    <div className="cp_customer-user-overview-header">
      <div className="cp_customer-user-overview-header_info-container">
        <div className="cp_customer-user-overview-header_avatar-container">
          <Avatar size="lg" src={ user.contact?.avatar.url.address } className="cp_customer-user-overview-header_avatar">
            { user.contact?.name[0] }
          </Avatar>
        </div>
        <div className="cp_customer-user-overview-header_details">
          <p className="cp_customer-user-overview-header_group-name">
            { user.contact?.name }
          </p>
          <p className="cp_customer-user-overview-header_created">
            { `${ translate("Created") } ${ dayjs(user.created * 1000)
              .format(MONTH_DAY_ABBR_FORMAT) }` }
          </p>
        </div>
      </div>
      <div className="cp_customer-user-overview-header_stats">
        <div className="cp_customer-user-overview-header_user-count-container" />
        <Tooltip title={ translate("Edit") }>
          <IconButton id="cp_customer-user-overview-header_edit-btn" size="small">
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
