import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../util/apiClient"
import * as GraphQL from "../../../../graphql"
import AffinitiesWrapper from "./AffinitiesWrapper"
import BasicChartWrapper from "./BasicChartWrapper"
import Divider from "../../../Divider"
import EthnicityChartWrapper from "./EthnicityChartWrapper"
import FamilyChartWrapper from "./FamilyChartWrapper"
import FollowersChartWrapper from "./FollowersChartWrapper"
import AudienceQualityChartWrapper from "./AudienceQualityChartWrapper"
import GenderChartWrapper from "./GenderChartWrapper"
import LanguagesChartWrapper from "./LanguagesChartWrapper"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import LocationWrapper from "./LocationWrapper"
import MaritalChartWrapper from "./MaritalChartWrapper"
import ReligionsChartWrapper from "./ReligionsChartWrapper"
import VocationWrapper from "./VocationWrapper"
import { fetchAudience, getAudienceQualityScores } from "../../../../state/socialProfileSlice"
import { useDispatch, useSelector } from "../../../../state/hooks"

import "../../Audience/index.sass"

export default function AccountAudienceDetails() {
  const profile = useSelector(({ socialProfile }) => socialProfile.profile)
  const audience = useSelector(({ socialProfile }) => socialProfile.audience)
  const audienceQualityScore = useSelector(({ socialProfile }) => socialProfile.audienceQualityScore)
  const { t: translate } = useTranslation([], { keyPrefix: "component.AudienceDetails" })
  const dispatch = useDispatch()

  useEffect(() => {
    if (profile === "init" || profile === "loading" || API.isError(profile)) return
    dispatch(fetchAudience({
      socialAccountId: profile.payload.socialAccount.id,
    }))
    if (audienceQualityScore === "init") {
      dispatch(getAudienceQualityScores(profile.payload.socialAccount.id))
    }
  }, [ profile, audienceQualityScore ])

  const showGenderWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(
      audience.payload.socialAccount.audienceDemographics?.genderStatistics?.female
      && audience.payload.socialAccount.audienceDemographics?.genderStatistics?.male,
    )
  }, [ audience ])
  const showMaritalWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.familyStatistics?.married
      && audience.payload.socialAccount.audienceDemographics?.familyStatistics?.single)
  }, [ audience ])
  const showParentalWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.familyStatistics?.parents)
  }, [ audience ])
  const showBasicWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.ageGroups.length
      || audience.payload.socialAccount.audienceDemographics?.incomeGroups.length
      || audience.payload.socialAccount.audienceDemographics?.educationLevel.length)
  }, [ audience ])

  const showLocationWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.cityGroups.length
      || audience.payload.socialAccount.audienceDemographics?.countryGroups.length
      || audience.payload.socialAccount.audienceDemographics?.stateGroups.length)
  }, [ audience ])

  const showAffinitiesWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.brandAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.interestAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.influenceAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.mediaAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.musicAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.sportAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.hashtagAffinities.length)
  }, [ audience ])

  const showVocationWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.occupationGroups.length
      || audience.payload.socialAccount.audienceDemographics?.industryGroups.length
      || audience.payload.socialAccount.audienceDemographics?.employerGroups.length
      || audience.payload.socialAccount.audienceDemographics?.universityGroups.length)
  }, [ audience ])

  const showEthnicityWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.ethnicityGroups.length)
  }, [ audience ])
  const showLanguagesWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.languageGroups.length)
  }, [ audience ])
  const showReligionWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.religionGroups.length)
  }, [ audience ])

  const socialAccount = useMemo(
    () => API.isSuccess(profile) ? profile.payload.socialAccount : null,
    [ profile ],
  )

  const showAudienceQuality = useMemo(
    () => socialAccount && socialAccount.network === GraphQL.Network.Instagram,
    [ socialAccount ],
  )

  return (
    <div className="cp_audience-details_component">
      <div className="cp_audience-details_component-widget">
        <FollowersChartWrapper />
      </div>
      { showAudienceQuality && (
        <AudienceQualityChartWrapper
          socialAccount={ socialAccount }
          id="cp_audience_quality_charts"
          className="cp_audience-details_component-widget"
        />
      ) }
      <div className="cp_audience-details_component-row" id="cp_audience-charts-row">
        { showGenderWidget && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-1">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Gender") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <GenderChartWrapper />
          </div>
        </div>
        ) }
        { (showMaritalWidget || showParentalWidget) && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-2">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Family") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            { audience === "init" || audience === "loading"
              ? <LoadingIndicatorCard />
              : (
                <>
                  { showMaritalWidget ? <MaritalChartWrapper /> : null }
                  { showParentalWidget ? <FamilyChartWrapper /> : null }
                </>
              )
            }
          </div>
        </div>
        ) }
      </div>
      { showBasicWidget && (
      <div className="cp_audience-details_component-widget">
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Basic") }
        </h6>
        <Divider />
        <div className="cp_audience-details_component-charts">
          <BasicChartWrapper />
        </div>
      </div>
      ) }
      { (showEthnicityWidget || showLanguagesWidget || showReligionWidget) && (
        <div className="cp_audience-details_component-row">
          { showEthnicityWidget && (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Ethnicity") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <EthnicityChartWrapper />
              </div>
            </div>
          ) }
          { showLanguagesWidget && (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Languages") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <LanguagesChartWrapper />
              </div>
            </div>
          ) }
          { showReligionWidget && (
            <div className="cp_audience-details_component-widget">
              <h6 className="cp_audience-details_component-widget-title">
                { translate("Religion") }
              </h6>
              <Divider />
              <div className="cp_audience-details_component-charts">
                <ReligionsChartWrapper />
              </div>
            </div>
          ) }
        </div>
      ) }
      { showLocationWidget && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <h6 className="cp_audience-details_component-widget-title">
              { translate("Location") }
            </h6>
            <LocationWrapper />
          </div>
        </div>
      ) }
      { showVocationWidget && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <VocationWrapper />
          </div>
        </div>
      ) }
      { showAffinitiesWidget && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <AffinitiesWrapper />
          </div>
        </div>
      ) }
    </div>
  )
}
