import React, { useEffect, useState } from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import { FormControlLabel, RadioGroup } from "@mui/material"
import { useParams } from "react-router-dom"
import Modal from "../Modal"
import LoadingIndicator from "../LoadingIndicator"
import { useDispatch, useSelector } from "../../state/hooks"
import ErrorHandler from "../ErrorHandler"
import { isError } from "../../util/apiClient"
import Radio from "../Radio"
import { CampaignPermissionType, CampaignRowFragment } from "../../graphql"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import EntityInfoRow from "../EntityInfoRow"
import {
  closeBrandManagersPermissionsModal,
  createCampaignPermission,
  getAvailableCampaigns,
} from "../../state/brandManagersPermissionsModal"
import {
  searchCustomerUserCampaigns,
  setPage,
  setSearching,
} from "../../state/customerUserPageSlice"
import { pushToast } from "../../state/toastSlice"
import SearchBar from "../SearchBar"

export default function BrandManagerPermissionsModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.BrandManagerPermissionsModal" })
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const [ searchInput, setSearchInput ] = useState("")
  const [ selectedPermissionType, setSelectedPermissionType ] = useState<null | CampaignPermissionType>(null)
  const [ selectedCampaign, setSelectedCampaign ] = useState<null | CampaignRowFragment>(null)

  const { customerID, userID } = useParams()

  const dispatch = useDispatch()
  const {
    availableCampaigns,
    open,
  } = useSelector(({ brandManagersPermissionsModal }) => brandManagersPermissionsModal)

  const handleRowClick = (row: CampaignRowFragment) => {
    setSelectedCampaign(row)
    setSelectedPermissionType(null)
  }

  const onClose = () => {
    setSearchInput("")
    setSelectedPermissionType(null)
    setSelectedCampaign(null)
    dispatch(closeBrandManagersPermissionsModal())
  }

  const onSubmit = async () => {
    if (selectedCampaign && selectedPermissionType && userID) {
      setSubmitLoading(true)
      await dispatch(createCampaignPermission({
        variables: {
          campaignId: selectedCampaign.id,
          permissionTypes: [ selectedPermissionType ],
          userId: userID,
        },
        onSuccess: async () => {
          if (!customerID || !userID) return
          dispatch(setSearching(true))
          await dispatch(searchCustomerUserCampaigns({
            customerId: customerID,
            userId: userID,
            startsWith: "",
          }))
          dispatch(setPage(1))
          dispatch(setSearching(false))
        },
        onError: () => {
          dispatch(pushToast({
            message: translate("There was an error adding this campaign client! Please try again."),
            type: "error",
          }))
        },
      }))
      setSubmitLoading(false)
      onClose()
    }
  }

  useEffect(() => {
    if (open && userID) {
      dispatch(getAvailableCampaigns({
        userId: userID,
        startsWith: searchInput,
      }))
    }
  }, [ open, searchInput ])

  const renderContent = () => {
    if (availableCampaigns === "init" || availableCampaigns === "loading") {
      return <LoadingIndicatorCard />
    }

    if (isError(availableCampaigns)) {
      return <ErrorHandler err={ availableCampaigns } />
    }

    const { rows } = availableCampaigns.payload.searchCampaignWithoutPermissionForUser

    return (
      <div className="active-permissions-container">
        { rows.map((row) => {
          const classNames = [ "campaign-row" ]
          if (row.id === selectedCampaign?.id) classNames.push("selected")
          return (
            <div
              id="cp_customer-management-modal-brand-manager-permissions-row"
              className={ classNames.join(" ") }
            >
              <EntityInfoRow
                onClick={ () => handleRowClick(row) }
                key={ row.id }
                name={ row.name }
                avatarSrc={ row.thumbnail?.url.address }
              />
            </div>
          )
        }) }
      </div>

    )
  }

  return (
    <Modal
      maxWidth="lg"
      className="cp_component_modal-customer-manage-campaign-permissions"
      title={ translate("Brand Manager Permissions") }
      subtitle={ translate("Add a campaign client with user permissions") }
      open={ open }
      closeAction={ onClose }
      secondaryAction={ onClose }
      primaryAction={ onSubmit }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Save") }
      disabled={ !selectedPermissionType }
      secondaryLabel={ translate("Cancel") }
    >
      <div className="modal-body">
        <div className="search-container">
          <p className="label_small-caps-bold">{ translate("Campaign") }</p>
          <SearchBar
            className="search-input"
            searchPlaceholderText={ translate("Search Campaign Name") }
            onChange={ (e) => setSearchInput(e.target.value) }
          />
          <div className="available-campaigns-container">
            { renderContent() }
          </div>
        </div>
        <div className="permissions-container">
          <p className="label_small-caps-bold">{ translate("Permissions") }</p>
          { selectedCampaign && (
          <RadioGroup className="cp_radio-group">
            <FormControlLabel
              className="radio-label"
              onClick={ () => setSelectedPermissionType(CampaignPermissionType.Approve) }
              value={ CampaignPermissionType.Approve }
              control={ <Radio /> }
              checked={ selectedPermissionType === CampaignPermissionType.Approve }
              label={ translate("Approve") }
            />
            <FormControlLabel
              className="radio-label"
              onClick={ () => setSelectedPermissionType(CampaignPermissionType.View) }
              value={ CampaignPermissionType.View }
              control={ <Radio /> }
              label={ translate("View") }
              checked={ selectedPermissionType === CampaignPermissionType.View }
            />
          </RadioGroup>
          )
        }
        </div>
      </div>
    </Modal>
  )
}
