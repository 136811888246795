import React, { JSX, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "../../state/hooks"
import { fetchList } from "../../state/listSlice"
import { toggleShowAcountSearch } from "../../state/listAddAccount"
import ToastController from "../../controller/Toast"
import ListOverview from "../../component/ListOverview"
import AddAccountToList from "../../component/ListManagement/AddAccountToList"

import "./style.sass"
import ListManagement from "../../component/ListManagement"
import PageShell from "../../component/PageShell"
import Button from "../../component/Button"

export default function List(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Lists" })
  const dispatch = useDispatch()
  const { listID } = useParams()
  const showAccountSearch = useSelector(({ listAddAccount }) => listAddAccount.showAccountSearch)

  useEffect(
    () => {
      if (!listID) return
      dispatch(fetchList(listID))
    },
    [ listID ],
  )

  return (
    <div className="cp_list_page">
      <ToastController />
      <PageShell title={ translate("Lists") } />
      <div className="cp_list_page-container">
        <section className="cp_list_page-overview">
          <ListOverview />
        </section>
        { !showAccountSearch && (
        <section className="cp_list_page-list-management">
          <ListManagement />
        </section>
        ) }
        { showAccountSearch && (
          <div className="cp_list_page-list-add-account">
            <Button
              isPrimary={ true }
              variant="contained"
              label={ translate("BACK TO LIST MANAGEMENT") }
              isEnabled={ true }
              onClick={ () => dispatch(toggleShowAcountSearch()) }
            />
            <AddAccountToList />
          </div>
        ) }
      </div>
    </div>
  )
}
