import React, {
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react"
import { Autocomplete, debounce } from "@mui/material"
import { Search } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import { fetchAutoCompleteCampaigns } from "../../state/ModalCommGroupSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./campaign-autocomplete.sass"

type Campaign = GraphQL.AutoCompleteCampaignFragment

interface Props {
  setSelectedCampaign: (campaign: Campaign) => void
  selectedCampaign: Campaign | null,
}

function CampaignAutocomplete({
  setSelectedCampaign,
}: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCommGroup" })
  const dispatch = useDispatch()
  const [ campaignsLoading, setCampaignsLoading ] = useState(false)
  const { campaigns } = useSelector(({ commGroupModal }) => commGroupModal)

  if (!API.isSuccess(campaigns)) return <LoadingIndicator size={ 40 } />

  const debouncedSearchCampaigns = useCallback(
    debounce(async (_e: SyntheticEvent<Element, Event>, inputValue: string) => {
      setCampaignsLoading(true)
      await dispatch(fetchAutoCompleteCampaigns(inputValue))
      setCampaignsLoading(false)
    }, DEFAULT_DEBOUNCE_WAIT),
    [ dispatch ], // only re-create when dispatch changes
  )

  const sortedCampaigns = useMemo(() => campaigns?.payload?.searchCampaign?.rows, [ campaigns ])

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    changeCampaign: string | GraphQL.AutoCompleteCampaignFragment,
  ) => {
    if (typeof changeCampaign !== "string") setSelectedCampaign(changeCampaign)
  }

  return (
    <Autocomplete
      multiple={ false }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") return option
        return option.name
      } }
      className="cp_component_autocomplete_campaigns"
      ListboxProps={ {
        className: "cp_component_autocomplete_campaigns_listbox",
      } }
      onInputChange={ debouncedSearchCampaigns }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-lists"
      options={ sortedCampaigns }
      openOnFocus={ false }
      loading={ campaignsLoading }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, list, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
              // eslint-disable-next-line react/prop-types
              className={ `${ props.className || "" } cp_component_autocomplete_lists_loading` }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ list.id }
          >
            <p>
              { list.name }
            </p>
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          className="cp_component_autocomplete_campaigns_input"
          { ...params as InputProps }
          placeholder={ translate("Enter Campaign Name") }
          InputProps={ {
            ...params.InputProps,
            startAdornment: <Search />,
          } }
        />
      )
}
    />
  )
}

export default CampaignAutocomplete
