import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"

export interface UnassignedThreadViewModalState {
  open: boolean
  thread?: GraphQL.ConversationThreadFragment
}

const initialState: UnassignedThreadViewModalState = {
  open: false,
}

export const unassignedThreadViewModal = createSlice({
  name: "UnassignedThreadViewModal",
  initialState,
  reducers: {
    openUnassignedThreadViewModal: (
      state,
      action: PayloadAction<GraphQL.ConversationThreadFragment>,
    ) => ({
      ...state,
      open: true,
      thread: action.payload,
    }),
    closeUnassignedThreadViewModal: (
      state,
    ) => ({
      ...state,
      open: false,
      thread: undefined,
    }),
  },
})

export const {
  closeUnassignedThreadViewModal,
  openUnassignedThreadViewModal,
} = unassignedThreadViewModal.actions
export default unassignedThreadViewModal.reducer
