import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  LinearProgress,
  Typography,
} from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-pro"
import "../communicationsSlidingPanel.sass"

import "./unassigned-content.sass"
import { v4 as uuidv4 } from "uuid"
import DataGrid from "../../../../DataGrid"
import EmptyElement from "../../../../EmptyElement"
import { useDispatch, useSelector } from "../../../../../state/hooks"
import { getMoreUnassignedThreads, getUnassignedThreads } from "../../../../../state/slidingPanelSlice/communications"
import LoadingIndicatorCard from "../../../../LoadingIndicatorCard"
import ThreadPreview from "../ThreadPreview"
import ContextMenu from "./UnassignedContentContextMenu"
import { ConversationThreadFragment } from "../../../../../graphql"
import SearchBar from "../../../../SearchBar"
import {
  setUnassignedIsLoading,
  setUnassignedSearchFilter,
  setUnassignedSearchInput,
  setUnassignedSearchPage,
} from "../../../../../state/slidingPanelSlice"

export default function UnassignedContent(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsTab" })
  const dispatch = useDispatch()

  const {
    unassignedThreadStatus,
    unassignedThreadContent,
    unassignedSearchInput,
    unassignedSearchFilter,
    unassignedIsLoading: isLoading,
    unassignedSearchPage: page,
  } = useSelector(({ slidingPanels }) => slidingPanels)

  const filterOptions = useMemo(() => [ {
    label: translate("Filter"),
    values: [
      {
        label: translate("None"), value: null, keyId: uuidv4(),
      },
      {
        label: translate("Archived"), value: "archived", keyId: uuidv4(),
      } ],
  } ], [ translate ])

  const loadQuery = async () => {
    dispatch(setUnassignedIsLoading(true))
    await dispatch(getUnassignedThreads({
      startsWith: unassignedSearchInput,
      limit: 50,
      page: 1,
      archived: unassignedSearchFilter.value === null ? undefined : true,
    }))
    dispatch(setUnassignedSearchPage(1))
    dispatch(setUnassignedIsLoading(false))
  }

  const loadMoreQuery = async () => {
    const newPage = page + 1
    dispatch(setUnassignedIsLoading(true))
    await dispatch(getMoreUnassignedThreads({
      startsWith: unassignedSearchInput,
      page: newPage,
      limit: 50,
      archived: unassignedSearchFilter.value === null ? undefined : true,
    }))
    dispatch(setUnassignedSearchPage(1))
    dispatch(setUnassignedIsLoading(false))
  }

  useEffect(() => {
    loadQuery()
  }, [ unassignedSearchInput, unassignedSearchFilter ])

  const COLUMNS: GridColDef[] = [
    {
      field: "from",
      headerName: translate("From"),
      sortable: true,
      renderCell: (params) => {
        const { thread }: { thread: ConversationThreadFragment } = params.row
        return (
          <Typography
            id="cp_component-unassigned-content_from-row"
            className="from-label-cell"
          >
            { thread.firstMessage.from.address }
          </Typography>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "content",
      headerName: translate("Content"),
      sortable: false,
      renderCell: (params) => {
        const { thread }: { thread: ConversationThreadFragment } = params.row
        return (
          <ThreadPreview
            id="cp_component-unassigned-content_content-row"
            created={ thread.created }
            subject={ thread.subject }
            content={ thread.firstMessage.text }
          />
        )
      },
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const { thread }: { thread: ConversationThreadFragment } = params.row
        return (
          <ContextMenu
            id="cp_component-unassigned-content_context-menu-row"
            thread={ thread }
          />
        )
      },
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ]

  const renderContent = () => {
    if (unassignedThreadStatus === "init" || unassignedThreadStatus === "loading") {
      return (
        <LoadingIndicatorCard />
      )
    }
    if (unassignedThreadStatus.status === "error") {
      return <Typography>Error!</Typography>
    }

    return (
      <DataGrid
        className="cp_component_unassigned-table"
        rowHeight={ 110 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        rows={ unassignedThreadContent.map((row) => ({
          id: row.id,
          thread: row,
        })) }
        onRowsScrollEnd={ () => {
          if (unassignedThreadStatus.payload.searchConversationThreads.totalCount > unassignedThreadContent.length) {
            loadMoreQuery()
          }
        } }
        loading={ isLoading }
        scrollEndThreshold={ 200 }
        slots={ {
          loadingOverlay: LinearProgress,
          noRowsOverlay: EmptyElement,
          noResultsOverlay: EmptyElement,
        } }
        rowSelection={ false }
      />
    )
  }

  return (
    <>
      <SearchBar
        onChange={ (e) => { dispatch(setUnassignedSearchInput(e.target.value)) } }
        lastSubmittedSearch={ unassignedSearchInput }
        filterOptions={ filterOptions }
        filterValue={ unassignedSearchFilter }
        setFilterValue={ (value) => dispatch(setUnassignedSearchFilter(value)) }
      />
      <Container id="cp_component_unassigned-content-container" className="cp_component_navigation-bar-templates-container">
        { renderContent() }
      </Container>
    </>
  )
}
