import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface ModalCommGroupState {
  commGroupModalOpen: boolean,
  lists: Status<GraphQL.SearchListsQuery>
  campaigns: Status<GraphQL.SearchCampaignQuery>
  subscribedUserIds: string[]
  createCommunicationGroup: Status<GraphQL.CreateCommunicationGroupMutation | null>
  editCommGroupID: string | null
  editCommGroupData: Status<GraphQL.GetCommunicationGroupForEditQuery | null>
  updateCommGroup: Status<GraphQL.EditCommunicationGroupMutation | null>
}

// Initialize state
const initialState: ModalCommGroupState = {
  commGroupModalOpen: false,
  lists: "init",
  campaigns: "init",
  subscribedUserIds: [],
  createCommunicationGroup: "init",
  editCommGroupID: null,
  editCommGroupData: "init",
  updateCommGroup: "init",
}

export const ModalcommGroupSlice = createSlice({
  name: "ModalCommGroup",
  initialState,
  reducers: {
    setCommGroupModalOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      commGroupModalOpen: action.payload,
    }),
    setLists: (
      state,
      action: PayloadAction<Status<GraphQL.SearchListsQuery>>,
    ) => ({
      ...state,
      lists: action.payload,
    }),
    setCampaigns: (
      state,
      action: PayloadAction<Status<GraphQL.SearchCampaignQuery>>,
    ) => ({
      ...state,
      campaigns: action.payload,
    }),
    setSubscribedUserIds: (
      state,
      action: PayloadAction<Array<string>>,
    ) => ({
      ...state,
      subscribedUserIds: action.payload,
    }),
    setCreateCommGroup: (
      state,
      action: PayloadAction<Status<GraphQL.CreateCommunicationGroupMutation | null>>,
    ) => ({
      ...state,
      createCommunicationGroup: action.payload,
    }),
    resetCreateCommGroup: (state) => ({ ...state, createCommunicationGroup: "init" }),
    seteditCommGroupID: (
      state,
      action: PayloadAction<string | null>,
    ) => ({
      ...state,
      editCommGroupID: action.payload,
    }),
    resetEditCommGroupID: (state) => ({ ...state, editCommGroupID: null }),
    setEditCommGroup: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationGroupForEditQuery | null>>,
    ) => ({
      ...state,
      editCommGroupData: action.payload,
    }),
    resetEditCommGroup: (state) => ({ ...state, editCommGroupData: "init" }),
    setUpdateCommGroup: (
      state,
      action: PayloadAction<Status<GraphQL.EditCommunicationGroupMutation | null>>,
    ) => ({
      ...state,
      updateCommGroup: action.payload,
    }),
    resetUpdateCommGroup: (state) => ({ ...state, updateCommGroup: "init" }),
  },
})

export const {
  setCommGroupModalOpen,
  setLists,
  setCampaigns,
  setSubscribedUserIds,
  setCreateCommGroup,
  resetCreateCommGroup,
  seteditCommGroupID,
  setEditCommGroup,
  resetEditCommGroup,
  setUpdateCommGroup,
  resetUpdateCommGroup,
  resetEditCommGroupID,
} = ModalcommGroupSlice.actions
export default ModalcommGroupSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchLists = (
  network: GraphQL.Network | GraphQL.Network[],
  startsWith?: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setLists("loading"))

  const listResults = await API.fetchLists({
    startsWith,
    networkFilter: network,
    limit: 20,
  })

  dispatch(setLists(listResults))
}

export const fetchAutoCompleteCampaigns = (
  startsWith: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  const campaignsResults = await API.fetchCampaignForAutoComplete(startsWith)
  dispatch(setCampaigns(campaignsResults))
}

export const submitCommunicationGroup = (
  name: string,
  labels: string[],
  suggestionListIds: string[],
  campaignId: string,
  subscribedUserIds: string[],
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCreateCommGroup("loading"))

  const result = await API.createCommunicationCall({
    name,
    labels,
    suggestionListIds,
    campaignId,
    subscribedUserIds,
  })

  dispatch(setCreateCommGroup(result))
}

export const fetchEditCommGroup = (id: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setEditCommGroup("loading"))

  const result = await API.getCommGroupforEditing(id)

  dispatch(setEditCommGroup(result))
}

export const editCommunicationGroup = (
  communicationGroupId: string,
  name: string,
  labels: string[],
  suggestionListIds: string[],
  campaignId: string,
  subscribedUserIds: string[],
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setUpdateCommGroup("loading"))

  const result = await API.editCommunicationGroup({
    communicationGroupId,
    name,
    labels,
    suggestionListIds,
    campaignId,
    subscribedUserIds,
  })

  dispatch(setUpdateCommGroup(result))
}
