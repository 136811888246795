import React, { useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import * as API from "../../util/apiClient"
import Modal from "../Modal"
import Button from "../Button"
import { useDispatch, useSelector } from "../../state/hooks"
import { pushToast } from "../../state/toastSlice"
import {
  setModalCustomerActivation,
  sendActivateCustomer,
  sendDeactivateCustomer,
  resetModalCustomerActivationState,
} from "../../state/customersPageSlice"

import "./style.sass"

export default function ModalCustomerActivation() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCustomerActivation" })
  const dispatch = useDispatch()

  const {
    modalCustomerActivationState,
    modalCustomerActivationOpen,
    activateCustomerStatus,
    deactivateCustomerStatus,
  } = useSelector(({ customers }) => customers)

  const {
    id, companyName, userName, activate, updateCallback,
  } = modalCustomerActivationState

  useEffect(() => () => {
    dispatch(resetModalCustomerActivationState())
  }, [ dispatch ])

  useEffect(() => {
    const isAPISuccess = activate ? API.isSuccess(activateCustomerStatus) : API.isSuccess(deactivateCustomerStatus)
    if (isAPISuccess) {
      dispatch(pushToast({
        type: "success",
        message: activate ? `${ translate("successActivationMessage") }` : `${ translate("successDeactivationMessage") }`,
      }))
      if (updateCallback) updateCallback()
    } else if (API.isError(activateCustomerStatus) || API.isError(deactivateCustomerStatus)) {
      dispatch(pushToast({
        type: "error",
        message: activate ? `${ translate("errorActivateMessage") }` : `${ translate("errorDeactivateMessage") }`,
      }))
    }
  }, [ activateCustomerStatus, deactivateCustomerStatus ])

  const handleActivateCustomer = useCallback(() => {
    if (activate) {
      dispatch(sendActivateCustomer(id))
    } else {
      dispatch(sendDeactivateCustomer(id))
    }
    dispatch(setModalCustomerActivation(false))
  }, [ activate, id, dispatch ])

  if (!id) return null
  const modalTitle = activate ? `${ translate("Confirm Reactivation") }` : `${ translate("Confirm Deactivation") }`

  return (
    <Modal
      maxWidth="lg"
      className="cp_component-modal-customer-activation"
      open={ modalCustomerActivationOpen }
      title={ modalTitle }
      hasFooter={ false }
      closeAction={ () => dispatch(setModalCustomerActivation(false)) }
    >
      <div className="cp_component-modal-customer-activation-body">
        <p id="modalBodyMessageOne">
          { activate
            ? translate("bodyCopyReactivate", { userName, companyName })
            : translate("bodyCopyDeactivate", { userName, companyName }) }
        </p>
        <div className="cp_component-modal-customer-activation-body-ctas">
          <Button
            id="submitActivationButton"
            onClick={ handleActivateCustomer }
            label={ activate ? translate("YES, REACTIVATE") : translate("YES, DEACTIVATE") }
          />
          <Button
            id="cancelActivationButton"
            onClick={ () => dispatch(setModalCustomerActivation(false)) }
            label={ translate("Cancel") }
            isPrimary={ false }
          />
        </div>
      </div>
    </Modal>
  )
}
