import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { debounce } from "@mui/material"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import { searchSocialAccounts, setSelectedSocialAccount } from "../../../../../../state/unassignedThreadAssignModal"
import Input, { Props as InputProps } from "../../../../../Input"
import Autocomplete from "../../../../../Autocomplete"
import { isSuccess } from "../../../../../../util/apiClient"
import { CommunicationGroupNetworkAccountFragment } from "../../../../../../graphql"
import * as Constant from "../../../../../../util/constant"

// SocialAccountAutocomplete is disabled until a communication is selected
export default function SocialAccountAutocomplete() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.UnassignedThreadAssignModal" })
  const dispatch = useDispatch()

  const {
    socialAccounts,
    selectedSocialAccount,
    selectedCommunication,
  } = useSelector(({ unassignedTreadAssignModal }) => unassignedTreadAssignModal)

  const [ options, setOptions ] = useState<CommunicationGroupNetworkAccountFragment[]>([])

  const fetchSocialAccountOptions = (input: string) => {
    if (selectedCommunication) {
      dispatch(searchSocialAccounts({
        communicationGroupId: selectedCommunication.id,
        startsWith: input,
      }))
    }
  }

  useEffect(() => {
    if (selectedCommunication) {
      fetchSocialAccountOptions("")
    } else {
      dispatch(setSelectedSocialAccount(null))
    }
  }, [ selectedCommunication ])

  useEffect(() => {
    if (isSuccess(socialAccounts)) setOptions(socialAccounts.payload.searchCommunicationGroupNetworkAccount.rows)
  }, [ socialAccounts ])

  function getAutocompleteOptionLabel(
    option: string | CommunicationGroupNetworkAccountFragment,
  ): string {
    if (typeof option === "string") return option
    return option.socialAccount.userName
  }

  function handleOnChange(
    socialAccount: string
    | CommunicationGroupNetworkAccountFragment
    | (string | CommunicationGroupNetworkAccountFragment)[]
    | null,
  ): void {
    if (
      socialAccount == null
      || typeof socialAccount === "string"
      || Array.isArray(socialAccount)
    ) {
      dispatch(setSelectedSocialAccount(null))
      return
    }

    dispatch(setSelectedSocialAccount(socialAccount))
  }

  return (
    <Autocomplete
      disabled={ !selectedCommunication }
      filterOptions={ (x) => x }
      isOptionEqualToValue={ ({ id }) => id === selectedSocialAccount?.id }
      filterSelectedOptions={ true }
      getOptionLabel={ (o) => getAutocompleteOptionLabel(o) }
      options={ options }
      onInputChange={ debounce((_, newValue) => {
        fetchSocialAccountOptions(newValue)
      }, Constant.DEFAULT_DEBOUNCE_WAIT) }
      onChange={ (_, newValue) => handleOnChange(newValue) }
      value={ selectedSocialAccount }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          fullWidth={ true }
          label={ `${ translate("Social Account") }*` }
          placeholder={ translate("Select Social Account") }
        />
      ) }
    />

  )
}
