import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { GridColDef, GridColumnHeaderTitle } from "@mui/x-data-grid"
import { IconButton, LinearProgress } from "@mui/material"
import dayjs from "dayjs"
import { UnfoldMore } from "@mui/icons-material"
import { useNavigate, useParams } from "react-router-dom"
import DataGrid from "../DataGrid"
import {
  CustomerTeamMemberFragment,
  SearchUserByTitleSortColumn,
  TeamMemberSortColumn,
} from "../../graphql"
import EntityInfoRow from "../EntityInfoRow"
import Pill from "../Pill"
import Timestamp from "../DataGrid/RenderCellComponents/Timestamp"
import "./customer-table.sass"
import { useDispatch, useSelector } from "../../state/hooks"
import StatusDot from "../StatusDot"
import { toggleTeamMemberByTitleColumnSort, toggleTeamMemberColumnSort } from "../../state/customerSlice"
import CustomerTableContextMenu from "./CustomerTableContextMenu"
import * as Constant from "../../util/constant"

type Props = {
  teamMembers: CustomerTeamMemberFragment[]
  fetchMore: () => void
  refresh: () => Promise<void>
  isLoading: boolean
}

function NoCustomersOverlay() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerTable" })
  return (
    <div className="cp_customer-table_component-no-results">
      { translate("No matching team members.") }
    </div>
  )
}

export default function CustomerTable({
  teamMembers,
  fetchMore,
  isLoading,
  refresh,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerTable" })
  const dispatch = useDispatch()
  const { vanity, customerID } = useParams()
  const { teamMembersSearchToggle } = useSelector(({ customer }) => customer)
  const navigate = useNavigate()

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "name",
      sortable: false,
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Customer") } />
        </>
      ),
      renderCell: (params) => (
        <EntityInfoRow
          id="cp_component-customer-table_customer-entity"
          avatarSrc={ params.row.avatar }
          name={ params.row.name }
          subInfo={ params.row.username }
          extraSubInfo={ params.row.title.toUpperCase() }
          key={ params.row.id }
          avatarSize="lg"
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 2,
    },
    {
      field: "email",
      sortable: false,
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Email") } />
          <IconButton
            className="filter-icon"
            onClick={ () => {
              if (teamMembersSearchToggle === "name") dispatch(toggleTeamMemberColumnSort(TeamMemberSortColumn.Email))
              else dispatch(toggleTeamMemberByTitleColumnSort(SearchUserByTitleSortColumn.EmailAddress))
            } }
          >
            <UnfoldMore />
          </IconButton>
        </>
      ),
      renderCell: (params) => (
        <p
          id="cp_component-customer-table_customer-email-label"
          className="email-label"
        >
          { params.row.email }
        </p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 2,
    },
    {
      field: "created",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Created") } />
          <IconButton
            className="filter-icon"
            onClick={ () => {
              if (teamMembersSearchToggle === "name") dispatch(toggleTeamMemberColumnSort(TeamMemberSortColumn.Created))
              else dispatch(toggleTeamMemberByTitleColumnSort(SearchUserByTitleSortColumn.UserCreated))
            } }
          >
            <UnfoldMore />
          </IconButton>
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <Timestamp
          id="cp_component-customer-table_time-customer-timestamp"
          time={
          dayjs(params.row.created * 1000)
            .format(Constant.MONTH_DAY_ABBR_FORMAT).toUpperCase()
        }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "role",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Role") } />
          <IconButton
            className="filter-icon"
            onClick={ () => {
              if (teamMembersSearchToggle === "name") dispatch(toggleTeamMemberColumnSort(TeamMemberSortColumn.Role))
              else dispatch(toggleTeamMemberByTitleColumnSort(SearchUserByTitleSortColumn.RoleDisplay))
            } }
          >
            <UnfoldMore />
          </IconButton>
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <Pill
          id="cp_component-customer-table_time-customer-role"
          label={ params.row.role.toUpperCase() }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1.4,
    },
    {
      field: "activity",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Activity") } />
          <IconButton
            className="filter-icon"
            onClick={ () => {
              if (teamMembersSearchToggle === "name") dispatch(toggleTeamMemberColumnSort(TeamMemberSortColumn.LastActivity))
              else dispatch(toggleTeamMemberByTitleColumnSort(SearchUserByTitleSortColumn.UserStatus))
            } }
          >
            <UnfoldMore />
          </IconButton>
        </>
      ),
      sortable: false,
      renderCell: (params) => {
        const status = params.row.userStatusCode === "active" ? "success" : "inactive"
        const label = params.row.userStatusCode === "active" ? translate("Last Active") : translate("Deactivated")
        return (
          <div
            id="cp_component-customer-table_time-customer-status"
            className="status-stamp"
          >
            <StatusDot status={ status } />
            <p>
              { `${ label }: ${ dayjs(params.row.userStatusUpdated * 1000).format(Constant.MONTH_DAY_ABBR_FORMAT) }` }
            </p>
          </div>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1.4,
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <CustomerTableContextMenu
          id="cp_component-customer-table_time-customer-context-menu"
          userStatusCode={ params.row.userStatusCode }
          teamMember={ params.row.teamMember }
          refreshTable={ refresh }
        />
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ], [ translate ])

  return (
    <DataGrid
      className="cp_component_customer-table"
      rowHeight={ 100 }
      columnHeaderHeight={ 40 }
      disableColumnReorder={ true }
      hideFooter={ true }
      columns={ COLUMNS }
      rows={ teamMembers.map((teamMember) => ({
        id: teamMember.id,
        avatar: teamMember.contact.avatar.url.address,
        name: teamMember.contact.name,
        username: teamMember.username,
        title: teamMember.contact.title || "",
        created: teamMember.created,
        role: teamMember.role?.display || "",
        userStatusCode: teamMember.userStatus.code,
        userStatusUpdated: teamMember.userStatus.updated,
        email: teamMember.contact.email.address,
        teamMember,
      })) }
      pinnedColumns={ {
        left: [ "name" ],
        right: [ "ellipsisMenu" ],
      } }
      onRowClick={ (params) => {
        navigate(`/${ vanity }/customer-management/${ customerID }/user/${ params.row.id }/campaigns`)
      } }
      loading={ isLoading }
      onRowsScrollEnd={ fetchMore }
      scrollEndThreshold={ 200 }
      slots={ {
        loadingOverlay: LinearProgress,
        noRowsOverlay: NoCustomersOverlay,
        noResultsOverlay: NoCustomersOverlay,
      } }
    />
  )
}
