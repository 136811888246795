import React, {
  useMemo,
  useState,
  useEffect,
} from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { GridColDef } from "@mui/x-data-grid"
import { useParams } from "react-router-dom"
import * as GraphQL from "../../graphql"
import EntityInfoRow from "../EntityInfoRow"
import DataGrid from "../DataGrid"
import { formatPercent, shorthandNumber } from "../../util/miscHelper"
import { scoreModalTypes } from "../../util/constant"
import { ScoreBreakDown } from "../../util/types"
import Pill from "../Pill"
import Status from "../DataGrid/RenderCellComponents/Status"
import { useDispatch, useSelector } from "../../state/hooks"
import "./list-overview-table.sass"
import {
  fetchPublicListSocialProfile,
  setSelectedListSocialAccount,
  setPostsByImageId,
} from "../../state/publicListSlice"
import { rawDataToScoreBreakDownForPublicList, resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import EmptyElement from "../EmptyElement"
import PublicModalScoreBreakDown from "../PublicModalScoreBreakDown"
import { isSuccess } from "../../util/apiClient"

type Props = {
  suggestionListSocialAccounts: GraphQL.SuggestionListSocialAccountForPublicListFragment[]
  toggles: GraphQL.ToggleFragment[]
}

export default function ListOverviewTable({ suggestionListSocialAccounts, toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListOverviewTable" })
  const leftPanelStatus = useSelector(({ publicList }) => publicList.leftPanelStatus)
  const listSocialAccount = useSelector(({ publicList }) => publicList.loadedListSocialAccount)
  const [ engagementScoreModal, setEngagementScoreModal ] = useState(false)
  const [ clickedRowId, setClickedRowId ] = useState("")
  const dispatch = useDispatch()
  const { listCode } = useParams()

  useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  const handleEngagmentScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string) => {
    if (scoreBreakDown.scores.some((item) => item === null) || !listCode) return
    dispatch(rawDataToScoreBreakDownForPublicList(scoreBreakDown, modalType, listCode))
    setEngagementScoreModal(true)
  }

  const hasEngagmentRate = toggles.some((toggle) => toggle.name === "ToggleEngagementRate")

  const COLUMNS: GridColDef[] = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: "account",
        sortable: false,
        headerName: translate("Account"),
        renderCell: (params) => (
          <EntityInfoRow
            key={ params.row.id }
            name={ `@${ params.row.userName }` }
            subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("Followers") }` }
            avatarSrc={ params.row.avatarSrc }
            network={ params.row.network }
            avatarSize="lg"
          />
        ),
        disableColumnMenu: true,
        resizable: false,
        flex: 3,
      },
      {
        field: "score",
        headerName: hasEngagmentRate ? translate("Engagement") : translate("Score"),
        sortable: false,
        renderCell: (params) => {
          const {
            scoreValue, scoreBreakDown, engagementRate,
          } = params.row
          const scoreLabel = hasEngagmentRate ? formatPercent(2, engagementRate) : Math.round(scoreValue)
          if (!scoreLabel) return null
          return (
            <Pill
              label={ scoreLabel }
              handleClick={
              (scoreBreakDown && !hasEngagmentRate)
                ? () => { handleEngagmentScoreModal(scoreBreakDown, scoreModalTypes.ENGAGEMENT) }
                : () => {}
             }
            />
          )
        },
        disableColumnMenu: true,
        resizable: false,
        flex: 1,
      },
    ]
    if (leftPanelStatus === "expanded") {
      columns.push(
        {
          field: "status",
          headerName: translate("Status"),
          sortable: false,
          renderCell: (params) => (
            <Status
              status={ params.row.approvalStatus ? "success" : "error" }
              label={ params.row.approvalStatus ? translate("APPROVED") : translate("NOT APPROVED") }
            />
          ),
          disableColumnMenu: true,
          resizable: false,
          flex: 2,
        },
        {
          field: "feedback",
          headerName: translate("Feedback"),
          sortable: false,
          renderCell: (params) => (
            <Pill label={ params.row.feedbackCount } />
          ),
          disableColumnMenu: true,
          resizable: false,
          flex: 1.5,
        },
      )
    }
    return columns
  }, [ translate, leftPanelStatus ])

  const selectedID = useMemo(() => {
    if (isSuccess(listSocialAccount)) {
      return listSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.id
    } return ""
  }, [ listSocialAccount ])

  return (
    <>
      <DataGrid
        className="cp_component_public-list-overview-table"
        disableMultipleRowSelection={ true }
        // we are also using local state to keep track of selected row
        // because the first option does not resolve until the api request is completed
        // and there is a delay in the css styling selection of the row
        rowSelectionModel={ selectedID || clickedRowId }
        onRowSelectionModelChange={ (params) => {
          const accountFound = suggestionListSocialAccounts.find((acc) => acc.id === params[0])
          if (!listCode || !accountFound) return
          setClickedRowId(accountFound.id)
          dispatch(setSelectedListSocialAccount(accountFound))
          dispatch(fetchPublicListSocialProfile({
            listId: listCode,
            socialAccountId: accountFound.socialAccount.id,
          }))
          dispatch(setPostsByImageId("init"))
        } }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        slots={ {
        // Shows/hides column headers based on panel state
          columnHeaders: leftPanelStatus === "expanded" ? undefined : () => null,
          noRowsOverlay: EmptyElement,
          noResultsOverlay: EmptyElement,
        } }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        rows={ suggestionListSocialAccounts.map((account) => ({
          listSocialAccount: account,
          id: account.id,
          userName: account.socialAccount.userName,
          followers: account.socialAccount.socialAccountStatistics.followers,
          avatarSrc: account.socialAccount.profilePictureUrl,
          network: account.socialAccount.network,
          scoreValue: account.score?.value,
          approvalStatus: account.approvalStatus?.value,
          feedbackCount: account.comments.length,
          engagementRate: account.socialAccount.socialAccountStatistics.engagementRate,
          scoreBreakDown: { socialAccount: account.socialAccount, scores: [ account.score ] },
        })) }
      />
      <PublicModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
    </>
  )
}
