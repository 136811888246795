import React from "react"
import { useTranslation } from "react-i18next"
import "./tags.sass"
import Pill from "../Pill"

import * as GraphQL from "../../graphql"

type Props = {
  verticals: Array<GraphQL.SuggestionListVerticalFragment> | Array<GraphQL.VerticalFragment>
  color?: "default" | "error" | "success" | "info" | "primary" | "secondary"
}

export default function Tags({ verticals, color }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileOverview" })

  return (
    <div className="cp_profile-overview_component-tags-wrapper">
      <p className="label_small-caps-semibold">{ translate("Tags") }</p>
      <div className="cp_profile-overview_component-tags-wrapper-pill-container">
        { verticals.map((vertical, i) => (
          <Pill
            color={ color }
            // eslint-disable-next-line react/no-array-index-key
            key={ `${ vertical.id }-${ i }` }
            label={ vertical.name }
          />
        )) }
      </div>
    </div>
  )
}
