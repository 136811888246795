import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

import "./customer-table-context-menu.sass"
import IconButton from "../IconButton"

interface Props {
  id?: string
  refreshTable: () => Promise<void>
  enabled: boolean
}

export default function CustomerTableContextMenu({
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshTable,
  enabled,
}: Props) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerUserTableContextMenu" })

  const onEditPermissionsClick = () => {
    // Placeholder until implemented
  }

  return (
    <>
      <IconButton
        id={ id }
        className="cp_customer-table-context-menu_icon-button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
        disabled={ !enabled }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_customer-table-context-menu_menu-component"
        transformOrigin={ {
          horizontal: "center",
          vertical: "top",
        } }
      >
        <MenuList
          dense={ true }
          className="menu-list"
          id="cp_customer-table-context-menu_menu-component"
        >
          <MenuItem
            className="menu-item"
            onClick={ onEditPermissionsClick }
          >
            <ListItemText className="menu-item-label">
              { translate("Edit Permissions") }
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
