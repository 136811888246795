import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"

import { useParams } from "react-router-dom"
import ToastController from "../../controller/Toast"
import PageShell from "../../component/PageShell"
import CustomerUserOverview from "../../component/CustomerUserOverview"
import { useDispatch, useSelector } from "../../state/hooks"
import { getCustomer, getCustomerUser } from "../../state/customerUserPageSlice"
import { isSuccess } from "../../util/apiClient"
import LoadingIndicatorCard from "../../component/LoadingIndicatorCard"
import ErrorHandler from "../../component/ErrorHandler"
import ErrorPage from "../Error"
import { Scope } from "../../util/types"

export default function Customers() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.CustomerUser" })

  const { customerID, userID } = useParams()
  const dispatch = useDispatch()
  const { customerStatus, userStatus } = useSelector(({ customerUserPageSlice }) => customerUserPageSlice)
  const { user: currentUserStatus, scopes } = useSelector(({ user }) => user)

  useEffect(() => {
    if (customerID) dispatch(getCustomer({ customerId: customerID }))
  }, [ customerID ])

  useEffect(() => {
    if (userID) dispatch(getCustomerUser({ userId: userID }))
  }, [ userID ])

  const companyName = React.useMemo(() => {
    if (isSuccess(customerStatus)) return `/${ customerStatus.payload.customer.company?.name }` || ""
    return ""
  }, [ customerStatus ])

  const userName = React.useMemo(() => {
    if (isSuccess(userStatus)) return `/${ userStatus.payload.userForCustomerManagement?.username }` || ""
    return ""
  }, [ userStatus ])

  if (currentUserStatus === "init" || currentUserStatus === "loading") {
    return (
      <div className="cp_customers_page">
        <LoadingIndicatorCard size={ 50 } />
      </div>
    )
  }
  if (currentUserStatus.status === "error") {
    return <ErrorHandler />
  }
  if (!scopes.includes(Scope.DEVELOPMENT) || !scopes.includes(Scope.FEATURE_CUSTOMER_MANAGEMENT)) {
    return <ErrorPage statusCode={ 404 } />
  }

  return (
    <div className="cp_customers_page">
      <ToastController />
      <PageShell title={ `${ translate("Customers") }${ companyName }${ userName }` } />
      <div className="cp_customers_page-container">
        <CustomerUserOverview />
      </div>
    </div>
  )
}
