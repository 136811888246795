import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../util/apiClient"
import * as GraphQL from "../../../../graphql"
import AffinitiesWrapper from "./AffinitiesWrapper"
import BasicChartWrapper from "./BasicChartWrapper"
import Divider from "../../../Divider"
import EthnicityChartWrapper from "./EthnicityChartWrapper"
import FamilyChartWrapper from "./FamilyChartWrapper"
import FollowersChartWrapper from "./FollowersChartWrapper"
import GenderChartWrapper from "./GenderChartWrapper"
import LanguagesChartWrapper from "./LanguagesChartWrapper"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import LocationWrapper from "./LocationWrapper"
import MaritalChartWrapper from "./MaritalChartWrapper"
import ReligionsChartWrapper from "./ReligionsChartWrapper"
import VocationWrapper from "./VocationWrapper"
import { AUDIENCE_DISPLAY_TOGGLES } from "../../../../util/constant"
import { ToggleFragment } from "../../../../graphql"
import { fetchAudience } from "../../../../state/listSocialProfileSlice"
import { getAudienceQualityScores } from "../../../../state/socialProfileSlice"
import { useDispatch, useSelector } from "../../../../state/hooks"

import "../../Audience/index.sass"
import AudienceQualityChartWrapper from "../../SocialProfileTabs/AccountAudienceDetails/AudienceQualityChartWrapper"

type Props = {
  toggles: ToggleFragment[]
}

export default function AudienceDetails({ toggles }: Props) {
  const { listProfile, audience } = useSelector(({ listSocialProfile }) => listSocialProfile)
  const audienceQualityScore = useSelector(({ socialProfile }) => socialProfile.audienceQualityScore)
  const { t: translate } = useTranslation([], { keyPrefix: "component.AudienceDetails" })
  const dispatch = useDispatch()

  useEffect(() => {
    if (listProfile === "init" || listProfile === "loading" || API.isError(listProfile)) return
    dispatch(fetchAudience({
      socialAccountId: listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount.id,
    }))
    if (audienceQualityScore === "init") {
      dispatch(getAudienceQualityScores(listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount.id))
    }
  }, [ listProfile, audienceQualityScore ])

  const [
    displayFollowersOverTime,
    displayGender,
    displayQuality,
    displayFamilyStatus,
    displayAgeRange,
    displayIncomeRange,
    displayEducationLevel,
    displayEthnicities,
    displayLanguages,
    displayReligions,
    displayCountries,
    displayStates,
    displayCities,
    displayOccupations,
    displayIndustries,
    displayEmployers,
    displayUniversities,
    displayAffinities,
  ] = useMemo(() => [
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.GENDER),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.QUALITY),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.LANGUAGES),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.RELIGIONS),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.COUNTRIES),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.STATES),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.CITIES),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES),
    toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.AFFINITIES),
  ], [ toggles ])

  const genderWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(
      audience.payload.socialAccount.audienceDemographics?.genderStatistics?.female
      && audience.payload.socialAccount.audienceDemographics?.genderStatistics?.male,
    )
  }, [ audience ])
  const maritalWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.familyStatistics?.married
      && audience.payload.socialAccount.audienceDemographics?.familyStatistics?.single)
  }, [ audience ])
  const parentalWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.familyStatistics?.parents)
  }, [ audience ])
  const basicWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.ageGroups.length
      || audience.payload.socialAccount.audienceDemographics?.incomeGroups.length
      || audience.payload.socialAccount.audienceDemographics?.educationLevel.length)
  }, [ audience ])

  const locationWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.cityGroups.length
      || audience.payload.socialAccount.audienceDemographics?.countryGroups.length
      || audience.payload.socialAccount.audienceDemographics?.stateGroups.length)
  }, [ audience ])

  const affinitiesWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.brandAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.interestAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.influenceAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.mediaAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.musicAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.sportAffinities.length
      || audience.payload.socialAccount.audienceDemographics?.hashtagAffinities.length)
  }, [ audience ])

  const vocationWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.occupationGroups.length
      || audience.payload.socialAccount.audienceDemographics?.industryGroups.length
      || audience.payload.socialAccount.audienceDemographics?.employerGroups.length
      || audience.payload.socialAccount.audienceDemographics?.universityGroups.length)
  }, [ audience ])

  const ethnicityWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.ethnicityGroups.length)
  }, [ audience ])
  const languagesWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.languageGroups.length)
  }, [ audience ])
  const religionWidgetHasData = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.socialAccount.audienceDemographics?.religionGroups.length)
  }, [ audience ])

  const socialAccount = useMemo(
    () => API.isSuccess(listProfile)
      ? listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount
      : null,
    [ listProfile ],
  )

  const showAudienceQuality = useMemo(
    () => socialAccount && socialAccount.network === GraphQL.Network.Instagram,
    [ socialAccount ],
  )

  return (
    <div className="cp_audience-details_component">
      { displayFollowersOverTime && (
        <div className="cp_audience-details_component-widget">
          <FollowersChartWrapper />
        </div>
      ) }
      { (showAudienceQuality && displayQuality) && (
      <AudienceQualityChartWrapper
        socialAccount={ socialAccount }
        id="cp_audience_quality_charts"
        className="cp_audience-details_component-widget"
      />
      ) }
      <div className="cp_audience-details_component-row">
        { displayGender && genderWidgetHasData && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-1">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Gender") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <GenderChartWrapper />
          </div>
        </div>
        ) }
        { displayFamilyStatus && (maritalWidgetHasData || parentalWidgetHasData) && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-2">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Family") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            { audience === "init" || audience === "loading"
              ? <LoadingIndicatorCard />
              : (
                <>
                  { maritalWidgetHasData ? <MaritalChartWrapper /> : null }
                  { parentalWidgetHasData ? <FamilyChartWrapper /> : null }
                </>
              )
            }
          </div>
        </div>
        ) }
      </div>
      { (displayAgeRange || displayIncomeRange || displayEducationLevel) && basicWidgetHasData && (
      <div className="cp_audience-details_component-widget">
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Basic") }
        </h6>
        <Divider />
        <div className="cp_audience-details_component-charts">
          <BasicChartWrapper
            displayAgeRange={ displayAgeRange }
            displayIncomeRange={ displayIncomeRange }
            displayEducationLevel={ displayEducationLevel }
          />
        </div>
      </div>
      ) }
      <div className="cp_audience-details_component-row">
        { displayEthnicities && ethnicityWidgetHasData && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Ethnicity") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <EthnicityChartWrapper />
          </div>
        </div>
        ) }
        { displayLanguages && languagesWidgetHasData && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Languages") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <LanguagesChartWrapper />
          </div>
        </div>
        ) }
        { displayReligions && religionWidgetHasData && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Religion") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <ReligionsChartWrapper />
          </div>
        </div>
        ) }
      </div>
      { (displayCities || displayCountries || displayStates) && locationWidgetHasData && (
      <div className="cp_audience-details_component-widget">
        <div className="cp_audience-details_component">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Location") }
          </h6>
          <LocationWrapper
            displayCities={ displayCities }
            displayCountries={ displayCountries }
            displayStates={ displayStates }
          />
        </div>
      </div>
      )
        }

      { (displayOccupations || displayIndustries || displayEmployers || displayUniversities) && vocationWidgetHasData && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <VocationWrapper
              displayOccupations={ displayOccupations }
              displayIndustries={ displayIndustries }
              displayEmployers={ displayEmployers }
              displayUniversities={ displayUniversities }
            />
          </div>
        </div>
      ) }
      { displayAffinities && affinitiesWidgetHasData && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <AffinitiesWrapper />
          </div>
        </div>
      ) }
    </div>
  )
}
