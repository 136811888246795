import React from "react"
import "./style.sass"
import CommunicationsSidebar from "./CommunicationsSidebar"
import CommunicationsBody from "./CommunicationsBody"

export default function CommunicationsOverview() {
  return (
    <div className="cp_component_communications-overview">
      <CommunicationsSidebar />
      <CommunicationsBody />
    </div>
  )
}
