import { Dispatch } from "redux"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  ListsState,
  initialState as listsInitialState,
  reducers as listsReducers,
} from "./lists"

import {
  CampaignState,
  initialState as campaignsInitialState,
  reducers as campaignReducers,
} from "./campaigns"

import {
  ProfilesState,
  initialState as profilesInitialState,
  reducers as profilesReducers,
} from "./profiles"

import {
  CommunicationsState,
  initialState as communicationsInitialState,
  reducers as communicationsReducers,
} from "./communications"

import {
  CustomersState,
  initialState as customersInitialState,
  reducers as customersReducers,
} from "./customers"

import {
  SocialTrackersState,
  initialState as socialTrackerInitialState,
  reducers as socialTrackerReducers,
} from "./socialTracker"
import { RootState } from "../store"

// eslint-disable-next-line no-shadow
export enum PanelState {
  CAMPAIGNS = "campaigns",
  COMMUNICATIONS = "communications",
  CUSTOMERS = "customers",
  CLOSED = "closed",
  LISTS = "lists",
  PROFILES = "profiles",
  SOCIAL_TRACKER = "social-tracker",
}

// Sliding panel slice Interface and Initial State
export type SlidingPanelsSliceState =
  { panelOpen: PanelState } &
  ListsState &
  CampaignState &
  ProfilesState &
  CommunicationsState &
  CustomersState &
  SocialTrackersState

const initialState: SlidingPanelsSliceState = {
  panelOpen: PanelState.CLOSED,
  ...listsInitialState,
  ...campaignsInitialState,
  ...profilesInitialState,
  ...communicationsInitialState,
  ...customersInitialState,
  ...socialTrackerInitialState,
}

// Profile Slice
export const slidingPanelsSlice = createSlice({
  name: "SlidingPanels",
  initialState,
  reducers: {
    setPanelOpen: (state, action: PayloadAction<PanelState>) => ({
      ...state,
      panelOpen: action.payload,
    }),
    ...listsReducers,
    ...campaignReducers,
    ...profilesReducers,
    ...communicationsReducers,
    ...customersReducers,
    ...socialTrackerReducers,
  },
})

export const {
  setPanelOpen,
  setListsStatus,
  setListsContent,
  setListSearchInput,
  setListNetworkFilter,
  setListTypeFilter,
  setListSearchToggle,
  setListsByTagStatus,
  setCampaignSearchFilter,
  setCampaignSearchInput,
  setCampaignStatus,
  setCampaignsContent,
  setProfileSearchInput,
  setProfilesContent,
  setProfilesStatus,
  setProfileSearchToggle,
  setCommunicationsContent,
  setCommunicationsStatus,
  setCommunicationSearchFilter,
  setCommunicationSearchInput,
  setTemplatesContent,
  setTemplatesSearchInput,
  setTemplatesStatus,
  setCommunicationsTab,
  setCustomersStatus,
  setCustomersContent,
  setCustomersSearchInput,
  setCustomersSearchToggle,
  setCustomersByUserStatus,
  setMoreCustomersIsLoading,
  setCustomersSortDirection,
  setMoreTemplatesLoading,
  setProfileManagementModalOpen,
  setProfileManagementCurrentProfile,
  setProfileManagementSelectedIds,
  setProfileManagementModalSearchInput,
  setProfileManagementProfilesResult,
  setProfileManagementFilterCompare,
  toggleSelectedUsers,
  setProfileDeleteModalOpen,
  setSocialTrackers,
  setSocialTrackerSearchInput,
  setUnassignedThreadContent,
  setUnassignedThreadStatus,
  setUnassignedSearchInput,
  setUnassignedSearchFilter,
  setUnassignedIsLoading,
  setUnassignedSearchPage,
} = slidingPanelsSlice.actions

export const togglePanel = (panel: PanelState) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { slidingPanels: { panelOpen } } = getState()
  if (panelOpen === panel) {
    dispatch(setPanelOpen(PanelState.CLOSED))
  } else {
    dispatch(setPanelOpen(panel))
  }
}

export default slidingPanelsSlice.reducer
