import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  Autocomplete,
  debounce,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material"
import { Search } from "@mui/icons-material"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"

export interface AutocompleteLabels {
  name: string
  id: string
}

interface Props {
  setSelectedTeamMembers: (teamMembers: AutocompleteLabels[]) => void
  selectedTeamMembers: AutocompleteLabels[],
  notFoundLabel: string,
  className?: string,
  fullWidth?: boolean,
  label?: string,
  placeholder?: string,
}

function TeamMembersAutocomplete({
  setSelectedTeamMembers,
  selectedTeamMembers,
  notFoundLabel,
  className,
  fullWidth,
  label,
  placeholder,
}:Props) {
  const [ teamMembers, setTeamMembers ] = useState<AutocompleteLabels[]>([])

  const generateTeamMemberLabels = (teamMemberRows:(string | GraphQL.TeamMemberFragment)[]): AutocompleteLabels[] => {
    const teamMemberLabels = teamMemberRows.map((member) => {
      if (typeof member !== "string") {
        const {
          username, id, contact,
        } = member
        const teamMemberLabel = `${ contact.name } (${ username })`
        return { name: teamMemberLabel, id }
      }
      return { name: member, id: "" }
    })
    return teamMemberLabels
  }

  const searchTeamMembers = async (startsWith?: string) => {
    const result = await API.fetchUsersForSubscription(startsWith)
    if (API.isSuccess(result)) {
      setTeamMembers(generateTeamMemberLabels(result.payload.searchUserForSuggestionListSubscription.rows))
    } else {
      setTeamMembers([ { name: notFoundLabel, id: "" } ])
    }
  }

  const debouncedSearchTeamMembers = useCallback(debounce((e, inputValue) => {
    searchTeamMembers(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchTeamMembers ])

  const onAutoCompleteChange = (event: React.SyntheticEvent, members: (string | AutocompleteLabels)[]) => {
    // remove the add keyword in filtered options
    const cleanedMembers = members.map((member) => {
      if (typeof member === "string") return { name: member, id: "" }
      const { name, id } = member
      // eslint-disable-next-line quotes
      const newSubstring = `Add: "`
      if (!name.includes(newSubstring)) return member
      const cleanedMemeberName = name.replace(newSubstring, "").slice(0, -1)
      return { name: cleanedMemeberName, id }
    })
    setSelectedTeamMembers(cleanedMembers)
  }

  const filter = createFilterOptions<AutocompleteLabels>()

  const autoCompleteFilter = (members: AutocompleteLabels[], params: FilterOptionsState<AutocompleteLabels>) => {
    const { inputValue } = params

    // Suggest the creation of a new value
    const isExisting = members.some(({ name }) => inputValue.toLowerCase() === name.toLowerCase())

    const filtered = filter(members, params)
      .filter(({
        id: filteredId,
      }) => !selectedTeamMembers.some(({ id: selectedId }) => selectedId === filteredId))

    if (inputValue && !isExisting) filtered.push({ name: `Add: "${ inputValue }"`, id: inputValue })

    return filtered
  }

  useEffect(() => {
    searchTeamMembers()
  }, [])
  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedTeamMembers }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (member) => {
      // Value selected with enter, right from the input
        if (typeof member === "string") return member
        return member.name
      } }
      ListboxProps={ {
        className: "cp_component_autocomplete_teammembers-list",
      } }
      onInputChange={ debouncedSearchTeamMembers }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      filterOptions={ autoCompleteFilter }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-teamMembers"
      options={ [ ...teamMembers ].sort() }
      loading={ false }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, { name, id }, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ id }
          >
            { name }
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          className={ className || "" }
          fullWidth={ fullWidth || true }
          label={ label || "" }
          placeholder={ placeholder || "" }
          InputProps={ {
            ...params.InputProps,
            startAdornment: <Search />,
          } }
        />
      )
}
    />
  )
}

export default TeamMembersAutocomplete
