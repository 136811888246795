import React from "react"
import MuiIconButton, { IconButtonProps } from "@mui/material/IconButton"
import "./style.sass"

interface Props extends IconButtonProps {
  variant?: "outlined" | "filled"
  size?: "small" | "medium"
}

function IconButton(props: Props): React.JSX.Element {
  const {
    className = "",
    ...muiButtonProps
  } = props
  const classes = [ "cp_icon-button_component" ]
  if (className) classes.push(className)
  if (props.variant) classes.push(props.variant)
  if (props.size) classes.push(props.size)
  return (
    <MuiIconButton
      className={ classes.join(" ") }
      { ...muiButtonProps }
    />
  )
}

export default IconButton
