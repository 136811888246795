import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Communication Group Page Slice Interface and Initial State
export interface CommunicationsPageState {
  communicationGroup: Status<GraphQL.GetCommunicationGroupPageQuery>
}

const initialState: CommunicationsPageState = {
  communicationGroup: "init",
}

// Campaign Page Slice
export const CommunicationsPageSlice = createSlice({
  name: "CommunicationsPageSlice",
  initialState,
  reducers: {
    setCommunicationGroup: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationGroupPageQuery>>,
    ) => ({
      ...state,
      communicationGroup: action.payload,
    }),
  },
})

export const {
  setCommunicationGroup,
} = CommunicationsPageSlice.actions
export default CommunicationsPageSlice.reducer

export const getCommunicationGroup = (
  variables: GraphQL.GetCommunicationGroupQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCommunicationGroup("loading"))

  const result = await API.getCommunicationGroup(variables)

  dispatch(setCommunicationGroup(result))
}
