import React, { JSX } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"
import SolidGauge from "highcharts/modules/solid-gauge"

import * as GraphQL from "../../../graphql"
import { CHART_BASE_COLORS, GAUGE_CHART_BASE_COLOR } from "../../../util/constant"

type Props = {
  accountAQS: GraphQL.SocialAccountForAqsFragment
}

// Initialize modules
HighchartsMore(Highcharts)
SolidGauge(Highcharts)

const getGaugeOptions = (value: number): Highcharts.Options => ({
  chart: {
    type: "solidgauge",
  },
  title: {
    text: "",
  },
  pane: {
    center: [ "50%", "50%" ],
    size: "100%",
    startAngle: 0,
    endAngle: 360,
    background: [ {
      backgroundColor: GAUGE_CHART_BASE_COLOR,
      innerRadius: "60%",
      outerRadius: "100%",
      shape: "arc",
      borderWidth: 0,
    } ],
  },
  tooltip: {
    enabled: false,
  },
  yAxis: {
    stops: [
      [ 0, CHART_BASE_COLORS.light[0] ],
      [ 1, CHART_BASE_COLORS.light[0] ],
    ],
    min: 0,
    max: 100,
    lineWidth: 0,
    minorTickInterval: undefined,
    tickAmount: 0,
    tickWidth: 0,
    title: {
      y: -50,
    },
    labels: {
      enabled: false,
    },
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: -15,
        borderWidth: 0,
        useHTML: true,
        format: "",
      },
      linecap: "round",
      rounded: true,
    },
  },
  credits: {
    enabled: false,
  },
  series: [ {
    type: "solidgauge",
    name: "Progress",
    data: [ value ],
    dataLabels: {
      format: "<div class=\"cp_aqc-highcharts-label\"><span>{y}%</span></div>",
    },
    tooltip: {
      valueSuffix: " %",
    },
    innerRadius: "70%",
    radius: "90%",
    overshoot: 5,
  } ],
})

const rawToWhole = (num: number) => Math.ceil(num * 100)

function QualityAudienceChart({ accountAQS }: Props): JSX.Element {
  const scores = accountAQS.audienceQualityScore ? accountAQS.audienceQualityScore?.components : []
  const values = scores.map((item) => ({
    value: rawToWhole(item.raw), display: item.display, description: item.description,
  }))

  return (
    <div className="cp_aqc-highcharts">
      { values.map((entry) => (
        <div className="cp_aqc-highcharts-graph" key={ `gauge-${ entry.value }` }>
          <HighchartsReact
            key={ `gauge-${ entry.value }` }
            highcharts={ Highcharts }
            options={ getGaugeOptions(entry.value) }
          />
          <div className="cp_aqc-highcharts-info">
            <h6>{ entry.display }</h6>
            <p>
              { entry.description }
            </p>
          </div>
        </div>
      )) }
    </div>
  )
}

export default QualityAudienceChart
