import * as React from "react"
import { useTranslation } from "react-i18next"

import "./engagement-table.sass"
import { prettyPrintDecimal } from "../../../util/miscHelper"
import * as GraphQL from "../../../graphql"

type Props = {
  socialProfile: GraphQL.SocialProfileFragment
  /**
   * Display the post type section.
   * Please note that Post Type Data will only show for Instagram Network.
   * Other networks will not render this section despite the value of this property.
   */
  displayPostType?: boolean
  displayPostTypeReel?: boolean
}

export default function EngagementTable({
  socialProfile, displayPostType = true, displayPostTypeReel = true,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.EngagementTable" })
  const {
    accountEngagementRates,
    networkEngagementRates,
    accountEngagementRatesByReel,
    networkEngagementRatesByReel,
  } = socialProfile.socialAccountStatistics
  const decimalPlaces = (socialProfile.network === GraphQL.Network.Snapchat) ? 4 : 2

  const organicPosts = (accountEngagementRates.percentageSponsored == null) ? 0
    : (1 - accountEngagementRates.percentageSponsored)

  const organicPostsByReel = (accountEngagementRatesByReel.percentageSponsored == null) ? 0
    : (1 - accountEngagementRatesByReel.percentageSponsored)

  return (
    <>
      { displayPostType && (
      <>
        <p className="cp_engagement-table_component-table-title">
          { translate("Organic vs. Sponsored") }
        </p>
        <table className="cp_engagement-table_component-table">
          <thead>
            <tr>
              <th>
                { translate("Type") }
              </th>
              <th>
                { `${ translate("Posts") } %` }
              </th>
              <th>
                { `${ translate("Eng.") } %` }
              </th>
              <th>
                { `${ translate("Network Eng.") } %` }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                { translate("Organic") }
              </th>
              <td>
                { `${ prettyPrintDecimal(organicPosts, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(accountEngagementRates.organic, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(networkEngagementRates.organic, decimalPlaces) }%` }
              </td>
            </tr>
            <tr>
              <th scope="row">
                { translate("Sponsored") }
              </th>
              <td>
                { `${ prettyPrintDecimal(accountEngagementRates.percentageSponsored, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(accountEngagementRates.sponsored, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(networkEngagementRates.sponsored, decimalPlaces) }%` }
              </td>
            </tr>
          </tbody>
        </table>
        <p className="cp_engagement-table_component-note">
          { translate("Note Data from the past 6 months") }
        </p>
      </>
      ) }
      { displayPostTypeReel && socialProfile.network === GraphQL.Network.Instagram && (
      <>
        <p className="cp_engagement-table_component-table-title">
          { translate("Reel vs. Non-Reel") }
        </p>
        <table className="cp_engagement-table_component-table">
          <thead>
            <tr>
              <th>
                { translate("Type") }
              </th>
              <th>
                { `${ translate("Posts") } %` }
              </th>
              <th>
                { `${ translate("Eng.") } %` }
              </th>
              <th>
                { `${ translate("Network Eng.") } %` }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                { translate("Reel") }
              </th>
              <td>
                { `${ prettyPrintDecimal(organicPostsByReel, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(accountEngagementRatesByReel.organic, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(networkEngagementRatesByReel.organic, decimalPlaces) }%` }
              </td>
            </tr>
            <tr>
              <th scope="row">
                { translate("Non-Reel") }
              </th>
              <td>
                { `${ prettyPrintDecimal(accountEngagementRatesByReel.percentageSponsored, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(accountEngagementRatesByReel.sponsored, decimalPlaces) }%` }
              </td>
              <td>
                { `${ prettyPrintDecimal(networkEngagementRatesByReel.sponsored, decimalPlaces) }%` }
              </td>
            </tr>
          </tbody>
        </table>
        <p className="cp_engagement-table_component-note">
          { translate("Note Data from the past 90 days") }
        </p>
      </>
      )
      }
    </>
  )
}
