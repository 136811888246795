import React, { JSX, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import {
  AccountCircle,
  AddCircleOutline,
  GppMaybe,
  LocalAtm,
  MoreHoriz,
  OpenInNew,
} from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import * as Misc from "../../util/miscHelper"
import Dropdown from "../Dropdown"
import ModalPostDetails from "../ModalPostDetails"
import NSFWModalWrapper from "./NSFWModalWrapper"
import Tooltip from "../Tooltip"
import { ModalType } from "./nfswHelper"
import { NSFW_CONTENT, NSFW_ACCOUNT } from "../../util/constant"
import { Props as SocialAvatarProps } from "../SocialAvatar"
import { Scope, SuggestionListContentTabPathsEnum } from "../../util/types"
import { setSelectedAccountIds, setSelectedNetwork } from "../../state/modalAddToLists"
import { useDispatch, useSelector } from "../../state/hooks"
import { addListSocialAccountTopPost, isSuccess } from "../../util/apiClient"
import {
  fetchTopPosts,
  getSuggestionListPostsByImageTag,
  getSuggestionListPostsByKeyword,
} from "../../state/listSocialProfileSlice"

// NOTE: Ellipsis menu on both Account and Content Search Results will
// use the same styles
import "./ellipsisMenuCellStyle.sass"
import { pushToast } from "../../state/toastSlice"
import LoadingIndicator from "../LoadingIndicator"

export interface SearchContentEllipsisMenuOptions {
  showAddPostButton: boolean,
  showViewProfileButton: boolean,
  showViewMatchingPostsButton: boolean,
}

type Props = {
  handleNSFWUpdate: (postId: string, updateFrom: string) => Promise<void>,
  hasPersonality: boolean,
  network: GraphQL.Network,
  postId: string,
  socialAcctId: string,
  sponsored: boolean,
  showOptions: SearchContentEllipsisMenuOptions
  postDetailsProps: {
    engagementRate: string,
    isReel: boolean,
    isStory: boolean,
    media: GraphQL.PostMedia[],
    permalink: string,
    postComments: number,
    postContent: string,
    postContentHighlighted: Array<GraphQL.PostContentToken>,
    postDate: number,
    postExits: number,
    postImpressions: number,
    postLikes: number,
    postPlays: number,
    postReach: number,
    postReachEstimated: number,
    postSaves: number,
    postShares: number,
    postScreenshots: number,
    postSwipeUps: number,
    postHighlighted: boolean,
    postSaved: boolean,
    postTapsBackward: number,
    postTapsForward: number,
    postViews: number,
    socialAvatarProps: SocialAvatarProps,
  }
}

function renderSponsoredTag(
  sponsored: boolean,
  tooltipTitle: string,
): JSX.Element | null {
  if (!sponsored) return null

  return (
    // NOTE: The section wrapper is necessary because MUI's dropdown will
    // break if this tag is positioned absolutely and the ellipsis menu
    // cell is positioned relatively to accomodate.
    <section>
      <aside>
        <Tooltip arrow={ true } placement="left" title={ tooltipTitle }>
          <LocalAtm />
        </Tooltip>
      </aside>
    </section>
  )
}

// TODO: Begin enabling these onClick actions as related features come
// into existence
export default function ContentEllipsisMenuCell(props: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ContentEllipsisMenuCell" })

  const {
    vanity, contentTabPath, listID,
  } = useParams()
  const dispatch = useDispatch()
  const searchInput = useSelector(({ search }) => search.searchInput)

  const [ loadingAddPost, setLoadingAddPost ] = useState(false)
  const [ isNSFWModalOpen, setIsNSFWModalOpen ] = useState(false)
  const [ modalOpened, setModalOpened ] = useState<ModalType>(null)
  const [ openPostDetails, setOpenPostDetails ] = useState<boolean>(false)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)

  const {
    fetchedTopPosts, imageTagPosts, activeTagId, keywordPosts,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)

  const openNSFWModal = (modalType: ModalType) => {
    setModalOpened(modalType)
    setIsNSFWModalOpen(true)
  }

  const closeNSFWModal = () => {
    setIsNSFWModalOpen(false)
  }

  // Handle mark post nsfw
  const handlePostNsfwModalAction = async () => {
    const isAccountUpdate = modalOpened || NSFW_CONTENT
    const updateId = modalOpened === NSFW_ACCOUNT ? props.socialAcctId : props.postId
    await props.handleNSFWUpdate(updateId, isAccountUpdate)
    closeNSFWModal()
  }

  const handleQueueAccountForLists = () => {
    dispatch(setSelectedAccountIds([ props.socialAcctId ]))
    dispatch(setSelectedNetwork(props.network))
  }

  const kind = React.useMemo(() => {
    switch (contentTabPath) {
      case SuggestionListContentTabPathsEnum.TOP_POSTS:
        return GraphQL.SuggestionListSocialAccountCippusType.Featured
      case SuggestionListContentTabPathsEnum.KEYWORDS:
        return GraphQL.SuggestionListSocialAccountCippusType.Relevant
      case SuggestionListContentTabPathsEnum.IMAGE_TAGS:
        return GraphQL.SuggestionListSocialAccountCippusType.ImageTag
      default:
        return GraphQL.SuggestionListSocialAccountCippusType.Featured
    }
  }, [ contentTabPath ])

  const isAddPostDisabled = React.useMemo(() => {
    switch (kind) {
      case GraphQL.SuggestionListSocialAccountCippusType.Featured:
        return fetchedTopPosts.length >= 9 || fetchedTopPosts.some((post) => post.id === props.postId)
      case GraphQL.SuggestionListSocialAccountCippusType.Relevant:
        return keywordPosts.length >= 4 || !activeTagId || keywordPosts.some((post) => post.id === props.postId)
      case GraphQL.SuggestionListSocialAccountCippusType.ImageTag:
        return imageTagPosts.length >= 4 || !activeTagId || imageTagPosts.some((post) => post.id === props.postId)
      default:
        return false
    }
  }, [ fetchedTopPosts, imageTagPosts, kind, keywordPosts ])

  const handleAddPost = async () => {
    if (!listID) return
    setLoadingAddPost(true)
    const input: GraphQL.SuggestionListSocialAccountCippusAddMutationVariables = {
      input: {
        kind,
        socialAccountId: props.socialAcctId,
        postId: props.postId,
        suggestionListId: listID,
      },
    }
    if (kind === GraphQL.SuggestionListSocialAccountCippusType.ImageTag) {
      input.input.imageTagId = activeTagId
    }
    if (kind === GraphQL.SuggestionListSocialAccountCippusType.Relevant) {
      input.input.keywordId = activeTagId
    }
    const result = await addListSocialAccountTopPost(input)
    if (!isSuccess(result)) {
      dispatch(pushToast({
        type: "error",
        message: translate("Unable to add post"),
      }))
      setLoadingAddPost(false)
      return
    }
    // Refresh the list of posts after adding a new post
    if (kind === GraphQL.SuggestionListSocialAccountCippusType.Relevant) {
      await dispatch(getSuggestionListPostsByKeyword({
        listId: listID,
        socialAccountId: props.socialAcctId,
        keywordId: activeTagId,
      }))
    }
    if (kind === GraphQL.SuggestionListSocialAccountCippusType.ImageTag) {
      await dispatch(getSuggestionListPostsByImageTag({
        listId: listID,
        socialAccountId: props.socialAcctId,
        suggestionListImageTagId: activeTagId,
      }))
    }
    if (kind === GraphQL.SuggestionListSocialAccountCippusType.Featured) {
      await dispatch(fetchTopPosts({ listId: listID, socialAccountId: props.socialAcctId }))
    }
    setLoadingAddPost(false)
  }

  return (
    <div className="cp_component_search-results-ellipsis-menu-cell">
      { renderSponsoredTag(props.sponsored, translate("Sponsored Post")) }
      <Dropdown
        buttonType="custom"
        customButtonChildren={ <MoreHoriz /> }
        placement="left"
      >
        <List disablePadding={ true }>
          { scopes.includes(Scope.FEATURE_INFLUENCER_LIST) && (
            <ListItem disablePadding={ true } disableGutters={ true }>
              <ListItemButton onClick={ handleQueueAccountForLists }>
                <ListItemIcon>
                  <AddCircleOutline className="list-item-icon" />
                </ListItemIcon>
                <ListItemText primary={ translate("Add to List") } />
              </ListItemButton>
            </ListItem>
          ) }
          { props.showOptions.showAddPostButton && scopes.includes(Scope.FEATURE_INFLUENCER_LIST) && (
            <ListItem disablePadding={ true } disableGutters={ true }>
              <ListItemButton onClick={ handleAddPost } disabled={ isAddPostDisabled }>
                { loadingAddPost ? <LoadingIndicator /> : (
                  <>
                    <ListItemIcon>
                      <AddCircleOutline className="list-item-icon" />
                    </ListItemIcon>
                    <ListItemText primary={ translate("Add Post") } />
                  </>
                ) }
              </ListItemButton>
            </ListItem>
          ) }
          { props.showOptions.showViewProfileButton && (
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton
              onClick={ () => {
                const url = props.hasPersonality
                  ? Misc.generateProfileUrl(props.socialAcctId, vanity || "")
                  : Misc.generateSearchSocialAccountUrl(props.socialAcctId, vanity || "")

                window.open(url, "_blank")
              } }
            >
              <ListItemIcon>
                <OpenInNew className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Profile") } />
            </ListItemButton>
          </ListItem>
          ) }
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton onClick={
              (e) => {
                setOpenPostDetails(true)
                e.stopPropagation()
              } }
            >
              <ListItemIcon>
                <AccountCircle className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Post Details") } />
            </ListItemButton>
          </ListItem>
          { props.showOptions.showViewMatchingPostsButton && (
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton onClick={
              () => {
                const url = Misc.generateSearchSocialAccountContentUrl(
                  props.socialAcctId,
                  vanity || "",
                  searchInput.contentParams,
                )
                window.open(url, "_blank")
              } }
            >
              <ListItemIcon>
                <OpenInNew className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Matching Posts") } />
            </ListItemButton>
          </ListItem>
          ) }
          { scopes.includes(Scope.NSFW_FLAG) && (
            <ListItem disablePadding={ true } disableGutters={ true }>
              <ListItemButton onClick={ () => { openNSFWModal(NSFW_CONTENT) } }>
                <ListItemIcon>
                  <GppMaybe className="list-item-icon" />
                </ListItemIcon>
                <ListItemText primary={ translate("Mark NSFW") } />
              </ListItemButton>
            </ListItem>
          ) }
          { scopes.includes(Scope.NSFW_FLAG) && (
            <ListItem disablePadding={ true } disableGutters={ true }>
              <ListItemButton onClick={ () => { openNSFWModal(NSFW_ACCOUNT) } }>
                <ListItemIcon>
                  <GppMaybe className="list-item-icon" />
                </ListItemIcon>
                <ListItemText primary={ translate("Mark Account NSFW") } />
              </ListItemButton>
            </ListItem>
          ) }
        </List>
      </Dropdown>
      <NSFWModalWrapper
        modalType={ modalOpened }
        isModalOpen={ isNSFWModalOpen }
        closeModal={ closeNSFWModal }
        handlePostNsfwModalAction={ handlePostNsfwModalAction }
      />
      <ModalPostDetails
        open={ openPostDetails }
        closeAction={ () => setOpenPostDetails(false) }
        { ...props.postDetailsProps }
      />
    </div>
  )
}
