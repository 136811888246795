import React, { JSX, useState } from "react"
import moment from "moment"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import {
  ArrowBackIos,
  ArrowForwardIos,
  ContentPaste,
  HelpOutline,
  OpenInNew,
  PlayArrowRounded,
} from "@mui/icons-material"

import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import * as MiscHelper from "../../util/miscHelper"
import IconButton from "../IconButton"
import Modal, { ModalProps } from "../Modal"
import Pill from "../Pill"
import PostStats from "../PostStats"
import SocialAvatar, { Props as SocialAvatarProps } from "../SocialAvatar"
import Tooltip from "../Tooltip"
import { NSFWLabelWTooltip } from "../IconNSFW"
import { pushToast } from "../../state/toastSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"
import { Scope } from "../../util/types"

export interface ModalPostDetailsProps extends Omit<
  ModalProps,
  "children" | "primaryAction" | "primaryLabel" | "secondaryAction"
  | "secondaryLabel" | "title"
> {
  engagementRate: string,
  isReel: boolean,
  isStory: boolean,
  media: GraphQL.PostMediaFragment[],
  permalink: string,
  postComments: number,
  postContent: string,
  postContentHighlighted?: Array<GraphQL.PostContentToken>
  postDate: number,
  postExits: number,
  postImpressions: number,
  postLikes: number,
  postPlays: number,
  postReach: number,
  postReachEstimated: number,
  postSaves: number,
  postShares: number,
  postScreenshots: number,
  postSwipeUps: number,
  postSaved: boolean,
  postHighlighted: boolean,
  postTapsBackward: number,
  postTapsForward: number,
  postViews: number,
  socialAvatarProps: SocialAvatarProps,
}

function customActions(
  permalink: string,
  pushCopiedToClipboardToast: () => void,
  translate: TFunction,
): JSX.Element[] {
  const externalLink = (
    <Tooltip
      arrow={ true }
      title={ translate("External Post Link") }
    >
      <a
        aria-label={ permalink }
        href={ permalink }
        rel="noreferrer"
        target="_blank"
      >
        <IconButton className="cp_component_post-details-modal-custom-actions">
          <OpenInNew />
        </IconButton>
      </a>
    </Tooltip>
  )

  const copyPermalink = (
    <IconButton
      className="cp_component_post-details-modal-custom-actions"
      onClick={ async () => {
        await navigator.clipboard.writeText(permalink)
        pushCopiedToClipboardToast()
      } }
    >
      <Tooltip
        arrow={ true }
        title={ translate("Copy Post URL") }
      >
        <ContentPaste />
      </Tooltip>
    </IconButton>
  )

  return [
    externalLink,
    copyPermalink,
  ]
}

function renderAiImageAnalysis(
  media: GraphQL.PostMediaFragment,
  sectionTitle: string,
  tooltipTitle: string,
): JSX.Element | null {
  if (media.imageAnalysis == null || media.imageAnalysis.length === 0) {
    return null
  }

  const analyses = [ ...media.imageAnalysis ]
    .sort((a, b) => b.confidence - a.confidence)

  return (
    <article className="cp_component_post-details-modal-image-analysis">
      <p className="label_large-caps-bold">
        { sectionTitle }
        <Tooltip arrow={ true } title={ tooltipTitle }>
          <HelpOutline fontSize="small" />
        </Tooltip>
      </p>
      { analyses.map((a: GraphQL.ImageAnalysisConfidence) => (
        <Pill
          key={ `cp_component_post-details-modal-image-analysis-${ uuidv4() }` }
          label={ `
            ${ MiscHelper.capitalize(a.name) }:
            ${ MiscHelper.prettyPrintDecimal(a.confidence) }%
          ` }
        />
      )) }
    </article>
  )
}

function renderEngagementRate(
  engagementRate: string,
  engagementRateLabel: string,
  isStory: boolean,
): JSX.Element | null {
  if (isStory || engagementRate === "" || engagementRate === "0.00") return null

  let displayedEngagementRate = engagementRate
  // If engagementRate is just a decimal number, convert it to a string percentage
  if (!engagementRate.includes("%") && parseFloat(engagementRate) < 1) {
    displayedEngagementRate = `${ MiscHelper.prettyPrintDecimal(parseFloat(engagementRate), 2) }%`
  }

  return (
    <p className="label_large-caps-bold">
      { `${ engagementRateLabel }: ${ displayedEngagementRate }` }
    </p>
  )
}

function renderMediaCarouselIndicator(
  idx: number,
  activeIndex: number,
): JSX.Element {
  const activeClass = idx === activeIndex
    ? "cp_component_post-details-modal-media-indicator_active"
    : ""

  return (
    <div
      className={ activeClass }
      key={ `cp_component_post-details-modal-media-indicator-${ uuidv4() }` }
    />
  )
}

function renderLeftCarouselArrow(
  mediaLength: number,
  activeIndex: number,
  setActiveIndex: (idx: number) => void,
  ariaLabel: string,
): JSX.Element | null {
  const activeClass = mediaLength <= 1
    ? "cp_component_post-details-modal-carousel-button_inactive"
    : ""

  return (
    <button
      className={ activeClass }
      onClick={ () => {
        if (activeIndex === 0) {
          setActiveIndex(mediaLength - 1)
          return
        }

        setActiveIndex(activeIndex - 1)
      } }
      type="button"
      aria-label={ ariaLabel }
    >
      <ArrowBackIos />
    </button>
  )
}

function renderRightCarouselArrow(
  mediaLength: number,
  activeIndex: number,
  setActiveIndex: (idx: number) => void,
  ariaLabel: string,
): JSX.Element {
  const activeClass = mediaLength <= 1
    ? "cp_component_post-details-modal-carousel-button_inactive"
    : ""

  return (
    <button
      className={ activeClass }
      onClick={ () => {
        if (activeIndex === mediaLength - 1) {
          setActiveIndex(0)
          return
        }

        setActiveIndex(activeIndex + 1)
      } }
      type="button"
      aria-label={ ariaLabel }
    >
      <ArrowForwardIos />
    </button>
  )
}

function renderTypePill(
  isUnique: boolean,
  typeText: string,
): JSX.Element | null {
  if (!isUnique) return null

  return (
    <Pill
      label={ typeText }
      handleClick={ () => {} }
    />
  )
}

export default function ModalPostDetails(
  props: ModalPostDetailsProps,
): JSX.Element | null {
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)

  const { t: translate } = useTranslation(
    [],
    { keyPrefix: "component.ModalPostDetails" },
  )

  const dispatch = useDispatch()
  const [ activeIndex, setActiveIndex ] = useState<number>(0)
  const activeMedia = props.media[activeIndex]
  const { isNSFW } = props.socialAvatarProps
  const datePosted = props.postDate !== 0 ? moment(props.postDate * 1000)
    .format(Constant.LONGFORM_DATE_TIME) : ""

  if (props.media.length === 0) return null
  if (props.socialAvatarProps.network === GraphQL.Network.Snapchat && !scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)) return null

  const renderPostContent = () => {
    if (Array.isArray(props.postContentHighlighted) && props.postContentHighlighted.length > 0) {
      return props.postContentHighlighted.map((item) => (
        <span key={ `${ item.text }` } style={ { fontWeight: item.highlighted ? "bold" : "normal" } }>
          { item.text }
        </span>
      ))
    }
    // Fallback to props.postContent if postContentHighligted is not defined or empty
    return props.postContent
  }

  return (
    // TODO: See if props can be leveraged to hide action buttons and footer
    // instead of hiding with CSS
    <Modal
      className="cp_component_post-details-modal"
      closeAction={ props.closeAction }
      customTitle={ <SocialAvatar { ...props.socialAvatarProps } network={ props.socialAvatarProps.network } /> }
      customTopRightActions={
        customActions(
          props.permalink,
          () => {
            dispatch(pushToast({
              type: "success",
              message: translate("Post URL copied to clipboard!"),
            }))
          },
          translate,
        )
      }
      maxWidth="xl"
      open={ props.open }
      // The following props are intentionally hidden from view on this modal
      primaryAction={ () => {} }
      primaryLabel=""
      secondaryAction={ () => {} }
      secondaryLabel=""
      title=""
    >
      { /* Carousel */ }
      <section>
        <div style={ { backgroundImage: `url("${ activeMedia.thumbnailUrl }")` } }>
          <div />
        </div>
        <aside>
          { props.media.map((_, idx) => renderMediaCarouselIndicator(idx, activeIndex)) }
        </aside>
        { renderLeftCarouselArrow(props.media.length, activeIndex, setActiveIndex, translate("Left carousel arrow")) }
        <a className={ isNSFW ? "is-nsfw" : "" } href={ props.permalink } target="_blank" rel="noreferrer">
          <img src={ activeMedia.thumbnailUrl } alt={ props.postContent } />
          { activeMedia.mediaType === "image" ? null : <PlayArrowRounded /> }
          <NSFWLabelWTooltip
            isNSFW={ isNSFW }
            labelClass="light"
          />
        </a>
        { renderRightCarouselArrow(props.media.length, activeIndex, setActiveIndex, translate("Right carousel arrow")) }
      </section>

      { /* Date Posted and Content Text */ }
      <article>
        <p className="label_large-bold">
          <span className="cp_component_post-details-modal-date">
            { datePosted }
          </span>
          { renderTypePill(props.isReel, translate("Reel")) }
          { renderTypePill(props.isStory, translate("Story")) }
        </p>
        <p>{ renderPostContent() }</p>
      </article>

      { /* AI Image Analysis */ }
      {
        renderAiImageAnalysis(
          activeMedia,
          translate("AI Image Analysis"),
          translate("Photo analysis is generated..."),
        )
      }

      { /* Post Stats and Engagement Rate */ }
      <article className="cp_component_post-details-modal-stats">
        <PostStats
          isReel={ props.isReel }
          isStory={ props.isStory }
          isLens={ false }
          isSpotlightContent={ props.postHighlighted }
          network={ props.socialAvatarProps.network }
          postComments={ props.postComments }
          postContent={ props.postContent }
          postExits={ props.postExits }
          postImpressions={ props.postImpressions }
          postLikes={ props.postLikes }
          postPlays={ props.postPlays }
          postReach={ props.postReach }
          postReachEstimated={ props.postReachEstimated }
          postSaves={ props.postSaves }
          postShares={ props.postShares }
          postTapsBackward={ props.postTapsBackward }
          postTapsForward={ props.postTapsForward }
          postViews={ props.postViews }
          postScreenshots={ props.postScreenshots }
          postSwipeUps={ props.postSwipeUps }
          isSaved={ props.postSaved }
        />
        { renderEngagementRate(
          props.engagementRate,
          translate("Engagement Rate"),
          props.isStory,
        ) }
      </article>
    </Modal>
  )
}
