/* eslint-disable max-len */
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import RelevantPostsPreview from "../../Content/RelevantPostsPreview"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import {
  getPostsByImageId,
  setActiveImageId,
  setPostImageTags,
} from "../../../../state/publicListSlice"
import { isSuccess } from "../../../../util/apiClient"

function RelevantPostsByImageTag() {
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const { listCode } = useParams()
  const {
    activeImageId, postsByImageId, loadedListSocialAccount, postImageTags,
  } = useSelector(({ publicList }) => publicList)
  const dispatch = useDispatch()

  useEffect(() => {
    if (postsByImageId === "init") {
      if (isSuccess(loadedListSocialAccount) && listCode) {
        const {
          socialAccount: {
            id,
          },
          imageTagsWithPosts,
        } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
        const alphabetizedPosts = [ ...imageTagsWithPosts ].sort((a, b) => a.name.localeCompare(b.name))
        dispatch(setPostImageTags(alphabetizedPosts))
        dispatch(setActiveImageId(alphabetizedPosts[0].id))
        dispatch(getPostsByImageId({
          code: listCode,
          socialAccountId: id,
          suggestionListImageTagId: alphabetizedPosts[0].id,
        }))
      }
    }
  }, [ loadedListSocialAccount ])

  const handleImageTagChange = async (imageTagId: string) => {
    if (imageTagId !== activeImageId) {
      dispatch(setActiveImageId(imageTagId))
      if (listCode && isSuccess(loadedListSocialAccount)) {
        await dispatch(getPostsByImageId({
          code: listCode,
          socialAccountId: loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId.socialAccount.id,
          suggestionListImageTagId: imageTagId,
        }))
      }
    }
  }

  if (
    loadedListSocialAccount === "init"
      || loadedListSocialAccount === "loading"
      || postsByImageId === "init"
      || postsByImageId === "loading"
  ) {
    return <LoadingIndicatorCard />
  }

  if (postsByImageId.status === "error") {
    return <p>{ translateCommon("An unexpected error occurred!") }</p>
  }

  const {
    cippusImageTags: keywordPosts,
  } = postsByImageId.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId

  return (
    <RelevantPostsPreview
      classes={ [ "keyword-preview" ] }
      tags={ postImageTags }
      activeTag={ activeImageId || "" }
      posts={ keywordPosts.map(({ post }) => post) }
      dropdownSelectionAction={ (imageTagId: string) => handleImageTagChange(imageTagId) }
    />
  )
}

export default RelevantPostsByImageTag
