/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { TFunction } from "i18next"
import { GridValidRowModel } from "@mui/x-data-grid-pro"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"
import { pushToast } from "../toastSlice"
import { RootState } from "../store"

// List Group Slice Interface and Initial State
export interface ListAddAccount {
  showAccountSearch: boolean
  selectedAccounts: string[]
  addAccountStatus: Status<GraphQL.SuggestionListSocialAccountAddMutation | null>
  selectedAccountRows: GridValidRowModel[]
  showSelectedAccounts: boolean
}

const initialState: ListAddAccount = {
  showAccountSearch: false,
  selectedAccounts: [],
  addAccountStatus: "init",
  selectedAccountRows: [],
  showSelectedAccounts: false,
}

// List Group Slice
export const ListAddAccountSlice = createSlice({
  name: "ListAddAccount",
  initialState,
  reducers: {
    toggleShowAcountSearch: (
      state,
    ) => {
      state.showAccountSearch = !state.showAccountSearch
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.selectedAccounts = action.payload
    },
    setAddAccountStatus: (state, action: PayloadAction<Status<GraphQL.SuggestionListSocialAccountAddMutation | null>>) => {
      state.addAccountStatus = action.payload
    },
    setSelectedAccountRows: (state, action: PayloadAction<GridValidRowModel[]>) => {
      state.selectedAccountRows = action.payload
    },
    setShowSelectedAccounts: (state, action: PayloadAction<boolean>) => {
      state.showSelectedAccounts = action.payload
    },
  },
})

export const {
  toggleShowAcountSearch,
  setSelectedAccounts,
  setAddAccountStatus,
  setSelectedAccountRows,
  setShowSelectedAccounts,
} = ListAddAccountSlice.actions
export default ListAddAccountSlice.reducer

export const submitAccountsToList = async (
  suggestionListId: string,
  suggestionListCategoryId: GraphQL.InputMaybe<string> | undefined,
  hoarderAccountIds: string | string[],
  translate: TFunction,
) => async (dispatch: Dispatch) => {
  dispatch(setAddAccountStatus("loading"))
  const response = await API.addAccountToSuggestionList({
    suggestionListId, suggestionListCategoryId, hoarderAccountIds,
  })
  if (API.isSuccess(response)) {
    dispatch(setAddAccountStatus(response))
    pushToast({
      type: "success",
      message: translate("Success!"),
      additionalInfo: translate("The selected account(s) were added to the list!"),
    })(dispatch)
  } else if (API.isError(response)) {
    dispatch(setAddAccountStatus(response))
    pushToast({
      type: "error",
      message: translate("Error"),
      additionalInfo: translate("The selected account(s) have not been added to the list!"),
    })(dispatch)
  }
}

/**
 * Data grid pro's selection model only returns string IDs
 * This method will allow us to retroactively cache all the data for each selected row
 * @param sanitizedRowIds
 * @param rows
 * @returns
 */
export const handleRowSelect = (
  sanitizedRowIds: string[],
  rows: GridValidRowModel[],
) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    selectedAccounts: currentSelectedAccountIDs,
    selectedAccountRows: currentSelectedAccountRows,
  } = getState().listAddAccount

  const sanitizedRowDiff = sanitizedRowIds.filter((row) => !currentSelectedAccountIDs.includes(row))
  const currentRowDiff = currentSelectedAccountIDs.filter((row) => !sanitizedRowIds.includes(row))
  // If a new row is selected, find the row(s)
  if (sanitizedRowDiff.length > 0) {
    const newRows = rows.filter((row) => sanitizedRowDiff.includes(row.id))
    dispatch(setSelectedAccountRows([ ...currentSelectedAccountRows, ...newRows ]))
  }
  // If a row(s) is removed, remove the row from the cache
  if (currentRowDiff.length > 1) {
    const newSelectedAccountRows = currentSelectedAccountRows.filter((row) => !currentRowDiff.includes(row.id))
    dispatch(setSelectedAccountRows(newSelectedAccountRows))
  }

  dispatch(setSelectedAccounts(sanitizedRowIds))
}

export const clearSelectedItems = () => (
  dispatch: Dispatch,
) => {
  dispatch(setSelectedAccounts([]))
  dispatch(setSelectedAccountRows([]))
  dispatch(setShowSelectedAccounts(false))
}
