import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import AddIcon from "@mui/icons-material/Add"
import { AutocompleteRenderInputParams, debounce } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import * as Misc from "../../util/miscHelper"
import * as SearchHelper from "../../util/searchHelper"
import Autocomplete from "../Autocomplete"
import Button from "../Button"
import IconButton from "../IconButton"
import Input from "../Input"
import Modal from "../Modal"
import NetworkButton from "../NetworkButton"
import Pill from "../Pill"
import Switch from "../Switch"
import { ImageTagsSearch, KeywordsSearch } from "../ModalAdvancedOptions"
import { fetchImageTags, setImageTags } from "../../state/searchSlice"
import { pushToast } from "../../state/toastSlice"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  setNewTrackerToInitState,
  fetchSocialAccounts,
  createNewTrack,
} from "../../state/ModalNewTrackerSlice"

import "./style.sass"
import { PanelState, setPanelOpen } from "../../state/slidingPanelSlice"

function ModalNewTracker(): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalNewTracker" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()
  const { vanity } = useParams()
  const { socialAccounts, newTrack } = useSelector(({ modalNewTracker }) => modalNewTracker)
  const {
    imageTags: imageTagOptions,
  } = useSelector(({ search }) => search)

  const isNewTrackerActive = useSelector(({ modalNewTracker }) => modalNewTracker.isModalOpen)
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)
  const [ trackerModalParams, setTrackerModalParams ] = useState<GraphQL.GodSearchInput>(SearchHelper.initialSearchState())
  const [ keyWordsModal, setKeyWordsModal ] = useState(false)
  const [ trackerFormName, setTrackerFormName ] = useState("")
  const [ keywordInputValue, setKeywordInputValue ] = useState<string>("")
  const [ activeImageTag, setActiveImageTag ] = useState<string | null>(null)
  const [ imageTagsModal, setImageTagsModal ] = useState(false)
  const [ activeSocialAccount, setActiveSocialAccount ] = useState<GraphQL.SocialAccountRowFragment | null>(null)
  const [ engagementRateValue, setEngagementRateValue ] = useState("")

  const resetModalForm = () => {
    setTrackerModalParams(SearchHelper.initialSearchState())
    setKeyWordsModal(false)
    setTrackerFormName("")
    setKeywordInputValue("")
    setActiveImageTag(null)
    setImageTagsModal(false)
    setActiveSocialAccount(null)
    setEngagementRateValue("")
  }

  useEffect(() => () => {
    resetModalForm()
    // eslint-disable-next-line no-void
    void dispatch(setNewTrackerToInitState())
    // TypeScript type system expects the cleanup function to return void,
  }, [ dispatch ])

  function fetchImageTagsByStartsWithValue(startsWith: string): void {
    if (startsWith === "") {
      setActiveImageTag(null)
      dispatch(setImageTags("init"))
      return
    }

    dispatch(fetchImageTags(startsWith))
  }

  function fetchSocialAcctsWithValue(startsWith: string): void {
    if (startsWith === "") {
      setActiveImageTag(null)
      return
    }
    dispatch(fetchSocialAccounts(startsWith))
  }

  const closeModal = () => {
    resetModalForm()
    dispatch(setNewTrackerToInitState())
  }

  const handleSubmitNewTracker = () => {
    if (trackerFormName) {
      dispatch(createNewTrack(
        { name: trackerFormName, params: trackerModalParams },
        scopes,
      ))
    }
  }

  useEffect(() => {
    if (API.isSuccess(newTrack)) {
      dispatch(pushToast({
        type: "success",
        message: translate("Tracker created successfully!"),
        additionalInfo: newTrack.payload ? (
          <Link
            id="cp_modal_new_tracker-success-toast"
            className="cp-toast-link"
            onClick={ () => dispatch(setPanelOpen(PanelState.CLOSED)) }
            to={ `/${ vanity }/social-tracker/${ newTrack.payload.createContentAlert.xref }` }
          >
            { translate("GO TO TRACKER") }
          </Link>
        ) : null,
      }))
      closeModal()
    } else if (API.isError(newTrack)) {
      dispatch(pushToast({
        type: "error",
        message: translate("Unable to create new tracker, try again later"),
      }))
      closeModal()
    }
  }, [ newTrack ])

  const handleNetworksUpdate = (network: GraphQL.Network) => {
    const currModalState = { ...trackerModalParams }
    const { socialNetworks } = currModalState
    if (socialNetworks.includes(network)) {
      currModalState.socialNetworks = socialNetworks.filter((item) => item !== network)
    } else {
      currModalState.socialNetworks.push(network)
    }
    setTrackerModalParams(currModalState)
  }

  function handleEnterKey(
    event: React.KeyboardEvent,
    callback: () => void,
  ): void {
    if (event.key === "Enter") {
      event.preventDefault()
      callback()
    }
  }

  function addKeywordsBasic(): void {
    if (keywordInputValue == null || keywordInputValue === "") return
    const currModalState = { ...trackerModalParams }
    const keywords = currModalState.contentParams?.keywords
    const currentKeywordTags = keywords ? keywords.flatMap(({ tags }) => tags) : []

    const newKeywords = keywordInputValue.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword && !currentKeywordTags.includes(keyword))
      .map((keyword) => ({ tags: [ keyword ], inclusive: false }))

    const combinedKeywords: GraphQL.KeywordInput[] = keywords ? keywords.concat(newKeywords) : newKeywords
    if (currModalState.contentParams) currModalState.contentParams.keywords = combinedKeywords
    setKeywordInputValue("")
    setTrackerModalParams(currModalState)
  }

  function keywordLabel(keyword: string, inclusive: boolean): string {
    if (!inclusive) return keyword
    return `${ translate("All") } | ${ keyword }`
  }

  function removeKeyword(keyword: GraphQL.KeywordInput): void {
    const currModalState = { ...trackerModalParams }
    const keywords = currModalState.contentParams?.keywords

    const filteredKeywords = keywords
      ? keywords.filter(({ tags }) => !tags.some((tag) => tag === keyword.tags[0])) : []

    if (currModalState.contentParams) currModalState.contentParams.keywords = filteredKeywords
    setTrackerModalParams(currModalState)
  }

  const addKeywordsAdvanced = (
    exclusive: string[],
    inclusive: string[],
  ): void => {
    const currModalState = { ...trackerModalParams }

    const exclusiveKeywords: GraphQL.KeywordInput[] = [ ...exclusive ]
      .map((k) => ({ tags: [ k ], inclusive: false }))

    const inclusiveKeywords: GraphQL.KeywordInput[] = [ ...inclusive ]
      .map((k) => ({ tags: [ k ], inclusive: true }))

    if (currModalState.contentParams) {
      currModalState.contentParams.keywords = [
        ...exclusiveKeywords,
        ...inclusiveKeywords,
      ]
    }
    setTrackerModalParams(currModalState)
    setKeyWordsModal(false)
  }

  function addImageTagBasic(imageTag: string | (string | null)[] | null): void {
    if (imageTag == null || Array.isArray(imageTag)) return
    const currModalState = { ...trackerModalParams }
    const currentImageTags = currModalState.contentParams ? currModalState.contentParams.optionalImageTags : []

    const currentFlattenedTags = currentImageTags.flatMap(({ tags }) => tags)

    // Tag already exists. Reset form state and do nothing.
    if (currentFlattenedTags.some((tag) => tag === imageTag)) {
      setActiveImageTag(null)
      return
    }

    // Add unique tag to state
    const combinedTags = currentImageTags
      .concat([ { tags: [ imageTag ], inclusive: false } ])

    // Save image tag and reset image tag form state
    if (currModalState.contentParams) currModalState.contentParams.optionalImageTags = combinedTags
    setTrackerModalParams(currModalState)
    setActiveImageTag(null)
    dispatch(setImageTags("init"))
  }

  function getImageTagOptions(): string[] {
    if (
      imageTagOptions === "init"
      || imageTagOptions === "loading"
      || API.isError(imageTagOptions)
    ) {
      return []
    }

    return imageTagOptions.payload.searchImageTags.rows
  }

  function renderImageTagInput(params: AutocompleteRenderInputParams): JSX.Element {
    return (
      <Input
        { ...params }
        InputLabelProps={ {} }
        label=""
        placeholder={ translate("Add an image tag") }
      />
    )
  }

  function removeImageTag(imageTag: GraphQL.ImageTagInput): void {
    const currModalState = { ...trackerModalParams }
    const currentImageTags = currModalState.contentParams ? currModalState.contentParams.optionalImageTags : []
    const filteredImageTags = currentImageTags
      .filter(({ tags }) => !tags.some((tag) => tag === imageTag.tags[0]))

    if (currModalState.contentParams) currModalState.contentParams.optionalImageTags = filteredImageTags
    setTrackerModalParams(currModalState)
  }

  const addImageTagsAdvanced = (
    exclusive: string[],
    inclusive: string[],
  ): void => {
    const currModalState = { ...trackerModalParams }
    const exclusiveImageTags: GraphQL.ImageTagInput[] = [ ...exclusive ]
      .map((k) => ({ tags: [ k ], inclusive: false }))

    const inclusiveImageTags: GraphQL.ImageTagInput[] = [ ...inclusive ]
      .map((k) => ({ tags: [ k ], inclusive: true }))

    if (currModalState.contentParams) {
      currModalState.contentParams.optionalImageTags = [
        ...exclusiveImageTags,
        ...inclusiveImageTags,
      ]
    }
    setTrackerModalParams(currModalState)
    setImageTagsModal(false)
  }

  function getSocialAccountsOptions(): GraphQL.SocialAccountRowFragment[] {
    if (
      socialAccounts === "init"
      || socialAccounts === "loading"
      || API.isError(socialAccounts)
    ) {
      return []
    }

    return socialAccounts.payload.searchSocialAccounts.rows
  }

  type SocialAccoutOption = NonNullable<string | GraphQL.SocialAccountRowFragment>
    | (string | GraphQL.SocialAccountRowFragment)[]
    | null

  function addSocialAccount(socialAcctOption: SocialAccoutOption): void {
    if (socialAcctOption == null || Array.isArray(socialAcctOption)) return
    const currModalState = { ...trackerModalParams }
    const currentsocialAccountIds = currModalState.socialAccountIds ? currModalState.socialAccountIds : []
    const currAcctId = typeof socialAcctOption === "string" ? socialAcctOption : socialAcctOption.id

    // Tag already exists. Reset form state and do nothing.
    if (currentsocialAccountIds.some((id) => id === currAcctId)) {
      setActiveSocialAccount(null)
      return
    }

    // Add unique tag to state
    const combinedAccntIds = currentsocialAccountIds
      .concat(currAcctId)

    // Save social acct and reset selection form state
    if (currModalState.socialAccountIds) currModalState.socialAccountIds = combinedAccntIds
    setTrackerModalParams(currModalState)
    setActiveSocialAccount(null)
    dispatch(setImageTags("init"))
  }

  function renderSocialAcctInput(params: AutocompleteRenderInputParams): JSX.Element {
    return (
      <Input
        { ...params }
        InputLabelProps={ {} }
        label=""
        placeholder={ translate("Select Accounts") }
      />
    )
  }

  const engRateOperations = (e: string) => {
    const currModalState = { ...trackerModalParams }
    const engRate = Number(e)
    if (!Number.isNaN(engRate)) {
      if (currModalState.contentParams) currModalState.contentParams.minPostEngagement = engRate / 100
      setTrackerModalParams(currModalState)
    }
  }

  const handleBlurOnEngRate = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    engRateOperations(event.currentTarget.value)
    setEngagementRateValue(`${ event.currentTarget.value }%`)
  }

  // Handle input change to allow only numbers
  const handleEngRateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target
    if (!Number.isNaN(Number(value))) {
      setEngagementRateValue(value)
    }
  }

  const handleSponsoredChange = (checked: boolean) => {
    const currModalState = { ...trackerModalParams }
    if (currModalState.contentParams) {
      currModalState.contentParams.sponsored = checked
    }
    setTrackerModalParams(currModalState)
  }

  const isSponsored = (): boolean => {
    const currModalState = { ...trackerModalParams }
    if (currModalState.contentParams) {
      const { sponsored } = currModalState.contentParams
      return typeof sponsored !== "boolean" ? false : sponsored
    }
    return false
  }

  const closeAdvancedModal = (whichModal: string) => {
    if (whichModal === "keywords") {
      setKeyWordsModal(false)
    } else {
      setImageTagsModal(false)
    }
    closeModal()
  }

  return (
    <Modal
      title={ translate("New Tracker") }
      primaryLabel={ translate("CREATE TRACKER") }
      secondaryLabel={ translateCommon("Cancel") }
      open={ isNewTrackerActive }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ handleSubmitNewTracker }
      maxWidth="lg"
      className="cp_modal_new_tracker"
      disabled={ !trackerFormName }
    >
      <div className="cp_modal_new_tracker-wrapper">
        <Input
          id="cp_modal_new_tracker_name"
          className="social-tracker-name"
          value={ trackerFormName }
          placeholder={ translate("Enter Name") }
          onChange={ (e) => setTrackerFormName(e.currentTarget.value) }
          fullWidth={ true }
          label={ translate("SOCIAL TRACKER NAME") }
        />
        <div className="cp_modal_new_tracker-networks">
          <h5>
            { translate("NETWORK") }
          </h5>
          <div className="network-buttons">
            { Misc
              .buildNetworkListByScope(scopes)
              .map((network) => (
                <NetworkButton
                  id={ `cp_modal_new_tracker_${ network }` }
                  key={ network }
                  network={ network }
                  isActive={ trackerModalParams.socialNetworks.includes(network) }
                  onClick={ () => handleNetworksUpdate(network) }
                />
              )) }
          </div>
        </div>
        <div className="cp_modal_new_tracker-keywords">
          <div className="cp_modal_new_tracker-keywords-heading has-advanced-link">
            <h5>
              { translate("KEYWORDS") }
            </h5>
            <Button
              id="cp_modal_new_tracker_keyword_advanced"
              className="cp_advanced_link"
              variant="text"
              isEnabled={ true }
              isPrimary={ false }
              label={ translate("ADVANCED") }
              onClick={ () => { setKeyWordsModal(true) } }
            />
          </div>
          <Input
            id="cp_modal_new_tracker_keyword_input"
            label=""
            value={ keywordInputValue }
            placeholder={ translate("Add a term, @mention, or #tag") }
            onChange={ (e) => setKeywordInputValue(e.target.value) }
            onKeyDown={ (e) => handleEnterKey(e, addKeywordsBasic) }
            InputProps={ {
              endAdornment: (
                <IconButton
                  id="cp_component_modal-new-tracker_button-keyword-add"
                  onClick={ () => addKeywordsBasic() }
                >
                  <AddIcon />
                </IconButton>
              ),
            } }
          />
          { trackerModalParams.contentParams && (
          <div className="cp_modal_new_tracker-keywords-keywords-pills">
            { trackerModalParams.contentParams.keywords.map((keyword) => (
              <Pill
                className="cp_component_modal-new-tracker_keyword-pill"
                key={ keyword.tags[0] || "" }
                label={ keywordLabel(keyword.tags[0] || "", keyword.inclusive) }
                onDelete={ () => removeKeyword(keyword) }
              />
            )) }
          </div>
          ) }
          <KeywordsSearch
            classes="cp_modal_new_tracker-advanced-modal"
            onSave={ addKeywordsAdvanced }
            open={ keyWordsModal }
            closeAction={ () => closeAdvancedModal("keywords") }
            contentCloseLink={ () => { setKeyWordsModal(false) } }
            contentCloseLabel={ translate("BACK TO NEW TRACKER") }
          />

        </div>
        <div className="cp_modal_new_tracker-image_tags">
          <div className="cp_modal_new_tracker-image_tags-heading has-advanced-link">
            <h5>
              { translate("IMAGE TAGS") }
            </h5>
            <Button
              id="cp_modal_new_tracker_image-tag_advanced"
              className="cp_advanced_link"
              variant="text"
              isEnabled={ true }
              isPrimary={ false }
              label={ translate("ADVANCED") }
              onClick={ () => { setImageTagsModal(true) } }
            />
          </div>
          <Autocomplete
            id="cp_modal_new_tracker_image-tag_autocomplete"
            disablePortal={ false }
            freeSolo={ true }
            filterOptions={ (x) => x }
            filterSelectedOptions={ true }
            getOptionLabel={ (o) => o || "" }
            isOptionEqualToValue={ (tag) => tag === activeImageTag }
            loading={ imageTagOptions === "loading" }
            loadingText={ translate("Loading image tags...") }
            noOptionsText={ translate("Search for an image tag") }
            onInputChange={ debounce((_, newValue) => {
              fetchImageTagsByStartsWithValue(newValue)
            }, Constant.DEFAULT_DEBOUNCE_WAIT) }
            onChange={ (_, newValue) => addImageTagBasic(newValue) }
            options={ getImageTagOptions() }
            renderInput={ (params) => renderImageTagInput(params) }
            value={ activeImageTag }
            clearOnBlur={ true }
          />
          { trackerModalParams.contentParams?.optionalImageTags && (
          <div className="cp_modal_new_tracker-image_tags-pills">
            { trackerModalParams.contentParams.optionalImageTags.map((k) => (
              <Pill
                key={ k.tags[0] || "" }
                label={ keywordLabel(k.tags[0] || "", k.inclusive) }
                onDelete={ () => removeImageTag(k) }
              />
            )) }
          </div>
          ) }
          <ImageTagsSearch
            classes="cp_modal_new_tracker-advanced-modal"
            onSave={ addImageTagsAdvanced }
            open={ imageTagsModal }
            closeAction={ () => closeAdvancedModal("imagTags") }
            contentCloseLink={ () => { setImageTagsModal(false) } }
            contentCloseLabel={ translate("BACK TO NEW TRACKER") }
          />
        </div>
        <div className="cp_modal_new_tracker-accounts">
          <h5>
            { translate("ACCOUNTS") }
          </h5>
          <Autocomplete
            disablePortal={ false }
            filterOptions={ (x) => x }
            filterSelectedOptions={ true }
            getOptionLabel={ (option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") return option
              return option.userName
            } }
            isOptionEqualToValue={ (option) => option.id === activeSocialAccount?.id }
            loading={ socialAccounts === "loading" }
            loadingText={ translate("Loading social accounts...") }
            noOptionsText={ translate("Search for a social account") }
            onInputChange={ debounce((_, newValue) => {
              fetchSocialAcctsWithValue(newValue)
            }, Constant.DEFAULT_DEBOUNCE_WAIT) }
            onChange={ (_, newValue) => addSocialAccount(newValue) }
            options={ getSocialAccountsOptions() }
            renderInput={ (params) => renderSocialAcctInput(params) }
            value={ activeImageTag }
          />
        </div>
        <Input
          id="cp_modal_new_tracker_engagement-rate-field"
          className="social-tracker-engagement-rate"
          value={ engagementRateValue }
          placeholder={ translate("Enter Engagement Rate") }
          onChange={ (e) => handleEngRateChange(e) }
          onBlur={ (e) => handleBlurOnEngRate(e) }
          fullWidth={ true }
          label={ translate("MINIMUM ENGAGEMENT RATE") }
        />
        <div className="cp_modal_new_tracker-sponsored">
          <Switch
            id="cp_modal_new_tracker_sponsored-toggle"
            isChecked={ isSponsored() }
            handleChange={ (e, checked) => {
              handleSponsoredChange(checked)
            } }
          />
          <p>{ translate("Show only sponsored content") }</p>
        </div>
      </div>
    </Modal>
  )
}
export default ModalNewTracker
