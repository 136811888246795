import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from "dayjs"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import Divider from "../Divider"
import EntityInfoRow from "../EntityInfoRow"
import InfiniteScroll from "../InfiniteScroll"
import Input from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import Modal from "../Modal"
import SearchBar from "../SearchBar"
import { SuggestionListRowFragment } from "../../graphql"
import { pushToast } from "../../state/toastSlice"
import SuccessDialog from "../SuccessModal"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  getLists,
  getMoreLists,
  setListGroupModalOpen,
  setListGroupToEdit,
  setListsSearchInput,
  setSelectedListIds,
  submitCreateListGroup,
  toggleSelectedList,
} from "../../state/templateSlice"

import "./style.sass"

export default function ModalCreateListGroup() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateListGroup" })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const vanity = useParams()

  const {
    listsStatus,
    listsContent,
    listsSearchInput,
    selectedListIds,
    listGroupModalOpen: open,
    listGroupToEdit,
    listGroupUpdateCallback,
  } = useSelector((state) => state.template)

  const [ groupName, setGroupName ] = useState("")
  const [ loadingCTA, setLoadingCTA ] = useState(false)
  const [ isSuccessDialogOpen, setIsSuccessDialogOpen ] = useState(false)
  const [ createdGroupId, setCreatedGroupId ] = useState("")
  const [ page, setPage ] = useState(1)

  useEffect(() => {
    if (open) {
      if (listGroupToEdit) {
        // load list group to state
        setGroupName(listGroupToEdit.name)
        dispatch(setSelectedListIds(listGroupToEdit.suggestionLists.map((l) => l.id)))
      } else {
        setGroupName("")
        dispatch(setSelectedListIds([]))
      }
      dispatch(setListsSearchInput(""))
      dispatch(getLists("", 1))
    }
  }, [ open ])

  const ctaLabel = useMemo(() => {
    switch (true) {
      case loadingCTA:
        return <LoadingIndicator size={ 20 } />
      case Boolean(listGroupToEdit):
        return translate("Save")
      default:
        return translate("Create Group")
    }
  }, [ open, loadingCTA, listGroupToEdit ])
  const modalTitle = useMemo(() => listGroupToEdit ? translate("Update Group") : translate("Create Group"), [ open ])

  useEffect(() => {
    dispatch(getLists(listsSearchInput, 1))
    setPage(1)
  }, [ listsSearchInput ])

  const onClose = () => {
    dispatch(setListGroupModalOpen(false))
    dispatch(setListGroupToEdit({
      listGroup: undefined,
      listGroupUpdateCallback: undefined,
    }))
  }

  const handleSuccessClose = () => {
    setIsSuccessDialogOpen(false)
    onClose()
    if (listGroupUpdateCallback) listGroupUpdateCallback()
  }

  const redirectToNewGroup = () => {
    setIsSuccessDialogOpen(false)
    if (createdGroupId) {
      navigate(`/${ vanity.vanity }/list-groups/${ createdGroupId }`)
    } else {
      dispatch(pushToast({
        message: translate("Failed to navigate. Group ID is missing."),
        type: "error",
      }))
    }
  }

  const handleSubmit = async () => {
    setLoadingCTA(true)
    const result = await submitCreateListGroup(groupName, selectedListIds)
    setLoadingCTA(false)
    if (API.isSuccess(result)) {
      setIsSuccessDialogOpen(true)
      const newGroupId = result.payload?.suggestionListGroupCreate.id ?? ""
      setCreatedGroupId(newGroupId)
      onClose()
    } else {
      dispatch(pushToast({
        message: result.message || translate("There was an error. Please try again!"),
        type: "error",
      }))
    }
  }

  const handleUpdate = async () => {
    if (!listGroupToEdit) return

    setLoadingCTA(true)
    const oldListIds = listGroupToEdit.suggestionLists.map((l) => l.id)
    const listsToAdd = selectedListIds.filter((selectedId) => !oldListIds.includes(selectedId))
    const listsToRemove = oldListIds.filter((selectedId) => !selectedListIds.includes(selectedId))

    const result = await API.updateSuggestionListGroup({
      name: groupName,
      suggestionListGroupUpdateId: listGroupToEdit.id,
    })
    await API.suggestionListAddToGroup({
      suggestionListAddToGroupId: listGroupToEdit.id,
      listIds: Array.from(new Set(listsToAdd)),
    })

    await API.removeSuggestionListFromGroup({
      id: listGroupToEdit.id,
      listIds: Array.from(new Set(listsToRemove)),
    })

    setLoadingCTA(false)

    if (API.isSuccess(result)) {
      setIsSuccessDialogOpen(true)
      if (listGroupUpdateCallback) await listGroupUpdateCallback()
      onClose()
    } else {
      dispatch(pushToast({
        message: translate("There was an error. Please try again!"),
        type: "error",
      }))
    }
  }

  const renderContent = () => {
    if (listsStatus === "init" || listsStatus === "loading") {
      return <LoadingIndicator flexWrapperEnabled={ true } />
    }
    if (API.isSuccess(listsStatus)) {
      const oldListIds = listGroupToEdit?.suggestionLists.map((l) => l.id) || []
      const filteredListContent: SuggestionListRowFragment[] = []
      // search results contain duplicates. filter them out.
      listsContent.forEach((list) => {
        if (!list.suggestionListGroup && !oldListIds.includes(list.id)
        && filteredListContent.findIndex((l) => l.id === list.id) === -1) {
          filteredListContent.push(list)
        }
      })
      if (listGroupToEdit && listsSearchInput === "") filteredListContent.unshift(...listGroupToEdit.suggestionLists)

      return (
        <InfiniteScroll
          dataLength={ listsContent.length }
          hasMore={ listsStatus.payload.searchSuggestionList.totalCount > listsContent.length }
          next={ () => {
            dispatch(getMoreLists(listsSearchInput, page + 1))
            setPage((prev) => prev + 1)
          } }

        >
          { filteredListContent.map((l) => (
            <EntityInfoRow
              className="entity-info"
              name={ l.name }
              key={ l.id }
              network={ l.network }
              avatarSrc={ l.avatar?.url.address }
              checkboxEnabled={ true }
              checkboxValue={ selectedListIds.includes(l.id) }
              onClick={ () => dispatch(toggleSelectedList(l.id)) }
              subInfo={
                `${ translate("Created by") } ${ l.creator.username } 
                | ${ dayjs(l.created * 1000).format(Constant.LONGFORM_DATE) }`
              }
            />
          )) }
        </InfiniteScroll>
      )
    }
    return <p>Error!</p>
  }

  return (
    <>
      <Modal
        className="cp_component_modal-create-list-group"
        open={ open }
        closeAction={ onClose }
        title={ modalTitle }
        subtitle={ translate("Name and add lists to add to your group.") }
        primaryLabel={ ctaLabel }
        secondaryLabel={ translate("Cancel") }
        secondaryAction={ onClose }
        primaryAction={ listGroupToEdit ? handleUpdate : handleSubmit }
        disabled={ loadingCTA || selectedListIds.length === 0 || groupName.length === 0 }
      >
        <Input
          className="cp_component_modal-create-list-group-input"
          label={ translate("Name Your Group") }
          placeholder={ translate("Add Group Name") }
          value={ groupName }
          onChange={ (e) => setGroupName(e.target.value) }
        />
        <Divider className="cp_component_modal-create-list-group-divider" />
        <SearchBar
          className="cp_component_modal-create-list-group-input"
          onChange={ (e) => dispatch(setListsSearchInput(e.target.value)) }
          lastSubmittedSearch={ listsSearchInput }
          label={ translate("Search Lists") }
          searchPlaceholderText={ translate("Search Lists") }
          onChangeDelay={ 500 }
        />
        <div className="cp_component_modal-create-list-group-content">
          { renderContent() }
        </div>
      </Modal>
      <SuccessDialog
        isOpen={ isSuccessDialogOpen }
        handleClose={ handleSuccessClose }
        dialogAction={ redirectToNewGroup }
        dialogTitle={ listGroupToEdit ? translate("Update Group") : translate("Create Group") }
        dialogContextText={ listGroupToEdit
          ? translate("Successfully Updated Group!")
          : translate("Successfully Created Group!") }
        buttonLabel={ translate("GO TO LIST GROUP") }
      />
    </>
  )
}
