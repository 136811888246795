import React from "react"
import { useTranslation } from "react-i18next"
import { InputAdornment, debounce } from "@mui/material"
import TuneIcon from "@mui/icons-material/Tune"
import SearchIcon from "@mui/icons-material/Search"
import Input from "../Input"
import "./style.sass"
import Dropdown from "../Dropdown"
import FilterMenu, { FilterMenuOption, FilterMenuValue } from "../FilterMenu"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import Toggle, { ToggleMenuValue } from "../Toggle"

type Props = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    label?: string
    className?: string
    id?: string
    filterMenuComponent?: React.JSX.Element | null
    /*
    * If filterOptions defined, a menu will render as the Input end adornment
    */
    filterOptions?: FilterMenuOption[]
    /*
    * if toggleOptions defined, and filterOptions is NOT defined, a toggle will render as the Input end adornment
    */
    toggleOptions?: ToggleMenuValue[]
    lastSubmittedSearch?: string
    onChangeDelay?: number
    searchPlaceholderText?: string
    filterValue?: FilterMenuValue
    setFilterValue?: (value: FilterMenuValue) => void
    toggleValue?: string
    setToggleValue?: (value: string) => void
}

const SearchAdornment = (
  <InputAdornment position="start">
    <SearchIcon className="cp_component_search-bar-search-icon" />
  </InputAdornment>
)

function SearchBar({
  onChange,
  className,
  id,
  label,
  filterOptions = [],
  toggleOptions = [],
  lastSubmittedSearch = "",
  onChangeDelay = DEFAULT_DEBOUNCE_WAIT,
  searchPlaceholderText,
  filterValue = {
    label: "", value: null, keyId: "",
  },
  setFilterValue = () => {},
  filterMenuComponent = null,
  toggleValue = "",
  setToggleValue = () => {},
}: Props) {
  const classes = [ "cp_component_search-bar-input" ]
  if (className) className.split(" ").forEach((n) => classes.push(n))
  const newClasses = classes.join(" ")

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const [ inputValue, setInputValue ] = React.useState(lastSubmittedSearch)

  const debouncedOnChange = React.useMemo(() => debounce(onChange, onChangeDelay), [ onChange, onChangeDelay ])

  let endAdornment
  if (filterOptions.length > 0) {
    endAdornment = (
      <InputAdornment position="end">
        <Dropdown
          buttonType="custom"
          customButtonChildren={
            <TuneIcon className="cp_component_search-bar-tune-icon" />
                }
        >
          { filterMenuComponent !== null ? filterMenuComponent
            : (
              <FilterMenu
                filterOptions={ filterOptions }
                filterValue={ filterValue }
                setFilterValue={ setFilterValue }
              />
            )
                }
        </Dropdown>
      </InputAdornment>
    )
  } else if (toggleOptions.length > 0) {
    endAdornment = (
      <Toggle
        toggleOptions={ toggleOptions }
        value={ toggleValue }
        setValue={ (value) => setToggleValue(value) }
      />
    )
  } else {
    endAdornment = null
  }

  return (
    <Input
      id={ id }
      className={ newClasses }
      placeholder={ searchPlaceholderText || translate("Search") }
      fullWidth={ true }
      value={ inputValue }
      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedOnChange(e)
        setInputValue(e.target.value)
      } }
      label={ label }
      InputProps={ {
        startAdornment: SearchAdornment,
        endAdornment,
      }
      }
    />
  )
}

export default SearchBar
