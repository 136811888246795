import * as React from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../../../util/apiClient"
import BrandsWrapper from "./BrandsWrapper"
import Divider from "../../../Divider"
import EngagementChartWrapper from "./EngagementChartWrapper"
import EngagementMapWrapper from "./EngagementMapWrapper"
import EngagementTableWrapper from "./EngagementTableWrapper"
import ErrorHandler from "../../../ErrorHandler"
import ImageAnalysisWrapper from "./ImageAnalysisWrapper"
import PersonalityTraitsWrapper from "./PersonalityTraitsWrapper"
import PlaceholderCard from "../../AccountInsights/PlaceholderCard"
import PlaceholderEmptySplash from "../../AccountInsights/PlaceholderEmptySplash"
import RecentMediaWrapper from "./RecentMediaWrapper"
import { Network } from "../../../../graphql"
import { useSelector } from "../../../../state/hooks"

import "../../AccountInsights/account-insights-details.sass"
import "../style.sass"

export default function AccountInsightsDetails() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const {
    profile,
  } = useSelector(({ socialProfile }) => socialProfile)

  const shouldDisplayImageAnalysis = React.useMemo(() => {
    if (API.isSuccess(profile)) {
      const { network } = profile.payload.socialAccount
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ profile ])

  if (profile === "init" || profile === "loading") {
    return null
  }

  if (profile.status === "error") {
    return <ErrorHandler err={ profile } />
  }

  const {
    isPlaceholder,
    network,
  } = profile.payload.socialAccount

  if (isPlaceholder) {
    return (
      <div>
        <PlaceholderCard />
        <PlaceholderEmptySplash />
      </div>
    )
  }

  return (
    <div className="cp_account-insights-details_component">
      <div className="cp_account-insights-details_component-widget">
        <EngagementChartWrapper />
      </div>
      <div className="cp_account-insights-details_component-widget">
        <div>
          <h6 className="cp_account-insights-details_component-widget-title">
            { translate("Engagement by Post Type") }
          </h6>
          <Divider />
          <EngagementTableWrapper />
        </div>
      </div>
      <div className="cp_account-insights-details_component-widget">
        <EngagementMapWrapper />
      </div>
      <div className="cp_account-insights-details_component-row">
        { shouldDisplayImageAnalysis && (
        <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-2">
          <div>
            <h6 className="cp_account-insights-details_component-widget-title">
              { translate("AI Image Analysis") }
            </h6>
            <Divider />
            <ImageAnalysisWrapper />
          </div>
        </div>
        ) }
        <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-1">
          <div>
            <h6 className="cp_account-insights-details_component-widget-title">
              { translate("Recent Media") }
            </h6>
            <Divider />
            <RecentMediaWrapper />
          </div>
        </div>
      </div>
      { (network === Network.Instagram || network === Network.Facebook) && (
        <div className="cp_account-insights-details_component-widget">
          <h6 className="cp_account-insights-details_component-widget-title">
            { translate("Brands") }
          </h6>
          <Divider />
          <BrandsWrapper />
        </div>
      ) }
      { network !== Network.Snapchat && (
        <div className="cp_account-insights-details_component-widget">
          <PersonalityTraitsWrapper />
        </div>
      ) }
    </div>
  )
}
