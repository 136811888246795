import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import "./campaign-account-table.sass"
import {
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro"

import { InputAdornment } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { useSelector, useDispatch } from "../../../state/hooks"
import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import { shorthandNumber } from "../../../util/miscHelper"
import { fetchCampaign, setInitDeleteNetworkAccount } from "../../../state/campaignSlice"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../../state/scoreBreakDownSlice"
import { scoreModalTypes } from "../../../util/constant"
import Pill from "../../Pill"
import DataGrid from "../../DataGrid"
import EntityInfoRow from "../../EntityInfoRow"
import ContextMenu from "./CampaignAccountRowContextMenu"
import Button from "../../Button"
import AddAccountToCampaign from "./AddAccountToCampaign"
import Input from "../../Input"
import LoadingIndicator from "../../LoadingIndicator"
import EmptySplash from "../../EmptySplash"
import EmptyElement from "../../EmptyElement"
import { openNewDeliverableModal } from "../../../state/campaignDeliverableModalSlice"
import { Scope, ScoreBreakDown } from "../../../util/types"
import ModalScoreBreakDown from "../../ModalScoreBreakDown"

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      { ...props }
      slots={ {
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null,
      } }
    />
  )
}

interface AccountsStartsWith {
  currValue: string,
  handleValueChange: (e: string) => void
}

function AccountStartsWith({ currValue, handleValueChange }: AccountsStartsWith) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchResults" })
  return (
    <Input
      className="cp_component_search-results-search-input"
      placeholder={ translate("Search") }
      value={ currValue }
      onChange={ (e) => { handleValueChange(e.target.value) } }
      InputProps={ {
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              className="cp_component_search-results-search-icon"
            />
          </InputAdornment>
        ),
      } }
    />
  )
}

export default function CampaignAccountsTable() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignAccountsTable" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()
  const { campaignID } = useParams()
  const {
    campaign, campaignNetworkAccounts, deleteNetworkAccount,
  } = useSelector(({ campaignPage }) => campaignPage)
  const {
    scopes,
  } = useSelector(({ user }) => user)
  const [ showSearch, setShowSearch ] = useState(false)
  const [ networkAccounts, setNetworkAccounts ] = useState<GraphQL.CampaignNetworkAccountFragment[]>([])
  const [ startsWithFilter, setStartsWithFilter ] = useState("")
  const [ engagementScoreModal, setEngagementScoreModal ] = React.useState(false)

  const handleEngagmentScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string) => {
    if (scoreBreakDown.scores.some((item) => item === null)) return
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType))
    setEngagementScoreModal(true)
  }

  React.useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  const refreshAccounts = () => {
    if (campaignID) {
      dispatch(fetchCampaign({ campaignId: campaignID }))
    }
  }

  useEffect(() => {
    if (API.isSuccess(campaign)) {
      const accounts = campaign.payload.campaign.campaignNetworkAccounts
      setNetworkAccounts(accounts)
    }
  }, [ campaign ])

  useEffect(() => {
    if (API.isSuccess(deleteNetworkAccount)) {
      dispatch(setInitDeleteNetworkAccount())
      refreshAccounts()
    }
  }, [ deleteNetworkAccount ])

  useEffect(() => {
    if (startsWithFilter.length) {
      const accounts = networkAccounts.filter((account) => account.socialAccount.userName?.startsWith(startsWithFilter))
      setNetworkAccounts(accounts)
    } else if (!startsWithFilter.length && API.isSuccess(campaign)) {
      const accounts = campaign.payload.campaign.campaignNetworkAccounts
      setNetworkAccounts(accounts)
    }
  }, [ startsWithFilter ])

  const COLUMNS: GridColDef[] = [
    {
      field: "account",
      headerName: translate("ACCOUNT"),
      sortable: true,
      filterable: true,
      headerClassName: "account-header",
      filterOperators: getGridStringOperators(),
      valueGetter(params) {
        return params.row.account
      },
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          network={ params.row.network }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "network",
      headerName: translate("Network"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        {
          label: translate("Facebook"),
          value: GraphQL.Network.Facebook,
        },
        {
          label: translate("Instagram"),
          value: GraphQL.Network.Instagram,
        },
        {
          label: translate("Snapchat"),
          value: GraphQL.Network.Snapchat,
        },
        {
          label: translate("TikTok"),
          value: GraphQL.Network.Tiktok,
        },
        {
          label: translate("Youtube"),
          value: GraphQL.Network.Youtube,
        },
      ],
      filterOperators: getGridSingleSelectOperators(),
      valueGetter(params) {
        return params.row.network
      },
      renderCell: (params) => (
        params.row.network
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "score",
      headerName: translate("SCORE"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Pill
          label={ Math.floor(params.row.score) }
          handleClick={
          params.row.scoreBreakDown
            ? () => { handleEngagmentScoreModal(params.row.scoreBreakDown, scoreModalTypes.ENGAGEMENT) }
            : () => {}
         }
        />
      ),
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "incoming",
      headerName: translate("INCOMING"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.incoming) } />
      ),
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "pending",
      headerName: translate("PENDING"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.pending) } />
      ),
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "finalized",
      headerName: translate("FINALIZED"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.finalized) } />
      ),
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "live",
      headerName: translate("LIVE"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.live) } />
      ),
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="cp_campaign-accounts-table_component-column-context">
          <ContextMenu
            campaignNetworkAccount={ params.row.campaignAccount }
            onAddDeliverable={ () => {
              dispatch(openNewDeliverableModal({
                socialAccounts: networkAccounts,
                createCallback: () => dispatch(fetchCampaign({ campaignId: campaignID || "" })),
                preSelectedSocialAccount: params.row.campaignAccount,
              }))
            } }
          />
        </div>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "left",
      maxWidth: 60,
    },
  ]

  const handleSearchUpdate = (inputValue: string) => {
    setStartsWithFilter(inputValue)
  }

  const toggleShowSearch = () => {
    setShowSearch(!showSearch)
    if (API.isSuccess(campaignNetworkAccounts)) {
      refreshAccounts()
    }
  }

  if (campaign === "init" || campaign === "loading") {
    return (
      <div className="cp_campaign_component-tabs-content-controls loading">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  }
  if (API.isError(campaign)) {
    return (
      <div className="cp_campaign_component-tabs-content-controls empty">
        <EmptySplash
          headlineText={ translateCommon("No results found") }
          bodyText={ translateCommon("An unexpected error occurred!") }
        />
      </div>
    )
  }

  return (
    showSearch ? (
      <>
        <div className="cp_campaign_component-tabs-content-controls search">
          <Button
            label={ translate("BACK TO ACCOUNTS") }
            isEnabled={ true }
            onClick={ toggleShowSearch }
          />
          { /* TODO: Add Search and Sorting fields */ }
        </div>
        <AddAccountToCampaign
          isTikTokEnabled={ campaign.payload.campaign.enableTiktok }
          addAccountCallback={ () => {
            toggleShowSearch()
            refreshAccounts()
          } }
        />
      </>
    ) : (
      <>
        <div className="cp_campaign_component-tabs-content-controls accounts">
          <AccountStartsWith currValue={ startsWithFilter } handleValueChange={ handleSearchUpdate } />
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
            <Button
              label={ translate("ADD ACCOUNT") }
              isEnabled={ true }
              onClick={ toggleShowSearch }
              size="small"
            />
          ) }
        </div>
        <DataGrid
          getRowId={ (row) => row.id }
          className="cp_campaign-accounts-table_component"
          columnVisibilityModel={ {
            network: false,
          } }
          slots={ {
            columnMenu: CustomColumnMenu,
            noRowsOverlay: EmptyElement,
            noResultsOverlay: EmptyElement,
          } }
          disableRowSelectionOnClick={ true }
          rowHeight={ 90 }
          columnHeaderHeight={ 40 }
          columns={ COLUMNS }
          disableColumnReorder={ true }
          hideFooter={ true }
          pinnedColumns={
          {
            left: [ "account" ],
            right: [ "ellipsisMenu" ],
          }
        }
          rows={ networkAccounts.map(({
            id,
            numberOfDeliverablesWithoutContent,
            numberOfFinalizedDeliverables,
            numberOfLiveDeliverables,
            numberOfPendingDeliverables,
            socialAccount,
          }, i, accounts) => ({
            account: socialAccount.userName,
            profilePictureUrl: socialAccount.profilePictureUrl,
            followers: socialAccount.socialAccountStatistics.followers,
            network: socialAccount.network,
            id,
            incoming: numberOfDeliverablesWithoutContent,
            finalized: numberOfFinalizedDeliverables,
            live: numberOfLiveDeliverables,
            pending: numberOfPendingDeliverables,
            score: (socialAccount.engagementScore?.value || 0),
            campaignAccount: accounts[i],
            scoreBreakDown: { socialAccount, scores: [ socialAccount.engagementScore ] },
          })) || [] }
          loading={ false }
          scrollEndThreshold={ 200 }
        />
        <ModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
      </>
    )
  )
}
