import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as API from "../../util/apiClient"
import Modal from "../Modal"
import Button from "../Button"
import { getPossessive } from "../../util/miscHelper"
import { useDispatch, useSelector } from "../../state/hooks"
import { pushToast } from "../../state/toastSlice"
import {
  sendForgotPasswordEmail,
  setModalCustomerResetPassword,
  resetModalCustomerResetPasswordCustomer,
} from "../../state/customersPageSlice"

import "./style.sass"

export default function ModalCustomerResetPassword() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerResetPassword" })
  const dispatch = useDispatch()

  const {
    forgotPasswordEmailAttempt, modalCustomerResetPasswordOpen, modalCustomerResetPasswordCustomer,
  } = useSelector(({ customers }) => customers)

  useEffect(() => () => {
    dispatch(resetModalCustomerResetPasswordCustomer())
  }, [ dispatch ])

  const {
    email, name, vanity,
  } = modalCustomerResetPasswordCustomer

  useEffect(() => {
    if (API.isSuccess(forgotPasswordEmailAttempt)) {
      dispatch(pushToast({
        type: "success",
        message: translate("successMessage"),
      }))
    } else if (API.isError(forgotPasswordEmailAttempt)) {
      dispatch(pushToast({
        type: "error",
        message: translate("errorMessage"),
      }))
    }
  }, [ forgotPasswordEmailAttempt ])

  const handleResetPassword = () => {
    dispatch(sendForgotPasswordEmail(vanity, email))
    dispatch(setModalCustomerResetPassword(false))
  }

  return (
    <Modal
      maxWidth="lg"
      className="cp_component-modal-customer-reset-password"
      open={ modalCustomerResetPasswordOpen }
      title={ translate("title", { userName: name }) }
      hasFooter={ false }
      closeAction={ () => dispatch(setModalCustomerResetPassword(false)) }
    >
      <div className="cp_component-modal-customer-reset-password-body">
        <p id="modalBodyMessageOne">
          { translate("sureToReset") }
          { " " }
          <span>{ getPossessive(name) }</span>
          { " " }
          { translate("password") }
        </p>
        <p id="modalBodyMessageTwo">{ translate("emailMessage", { email }) }</p>
        <div className="cp_component-modal-customer-reset-password-body-ctas">
          <Button
            id="submitResetPasswordButton"
            onClick={ handleResetPassword }
            label={ translate("YES, RESET PASSWORD") }
          />
          <Button
            id="cancelResetPasswordButton"
            onClick={ () => dispatch(setModalCustomerResetPassword(false)) }
            label={ translate("CANCEL") }
            isPrimary={ false }
          />
        </div>
      </div>
    </Modal>
  )
}
