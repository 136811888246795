import React from "react"

import Modal from "../Modal"
import ScoreBreakDown from "../ScoreBreakDown"

import "./style.sass"

interface Props {
  isModalScoreOpen: boolean;
  closeModal: () => void;
}

export default function PublicModalScoreBreakDown({ isModalScoreOpen, closeModal }: Props) {
  return (
    <Modal
      open={ isModalScoreOpen }
      closeAction={ closeModal }
      hasFooter={ false }
      customTitle={ <p /> }
      maxWidth="xl"
      className="cp_component_modal-engagement-score"
    >
      <ScoreBreakDown />
    </Modal>
  )
}
