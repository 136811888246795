import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

import "./unassigned-context-menu.sass"
import IconButton from "../../../../IconButton"
import { useDispatch, useSelector } from "../../../../../state/hooks"
import { openUnassignedThreadAssignModal } from "../../../../../state/unassignedThreadAssignModal"
import { ConversationThreadFragment } from "../../../../../graphql"
import { openUnassignedThreadViewModal } from "../../../../../state/unassignedThreadViewModal"
import { getUnassignedThreads, updateUnassignedArchiveStatus } from "../../../../../state/slidingPanelSlice/communications"
import { pushToast } from "../../../../../state/toastSlice"
import { setUnassignedIsLoading, setUnassignedSearchPage } from "../../../../../state/slidingPanelSlice"

interface Props {
  id?: string
  thread: ConversationThreadFragment
}

export default function UnassignedContentContextMenu({ id, thread }: Props) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.UnassignedContentContextMenu" })
  const dispatch = useDispatch()
  const { unassignedSearchInput, unassignedSearchFilter } = useSelector(({ slidingPanels }) => slidingPanels)

  const refreshThreads = async () => {
    dispatch(setUnassignedIsLoading(true))
    await dispatch(getUnassignedThreads({
      startsWith: unassignedSearchInput,
      limit: 50,
      page: 1,
      archived: unassignedSearchFilter.value === null ? undefined : true,
    }))
    dispatch(setUnassignedSearchPage(1))
    dispatch(setUnassignedIsLoading(false))
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onViewClick = () => {
    dispatch(openUnassignedThreadViewModal(thread))
    onClose()
  }

  const onAssignClick = () => {
    dispatch(openUnassignedThreadAssignModal({ unassignedThreadId: thread.id }))
    onClose()
  }

  const onArchiveClick = () => {
    dispatch(updateUnassignedArchiveStatus({
      variables: {
        archive: true,
        conversationThreadId: thread.id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          message: translate("Communication is archived"),
          type: "success",
        }))
        refreshThreads()
      },
      onError: () => dispatch(pushToast({
        message: translate("There was an issue archiving this communication. Please try again!"),
        type: "error",
      })),
    }))
    onClose()
  }
  const onRestoreClick = () => {
    dispatch(updateUnassignedArchiveStatus({
      variables: {
        archive: false,
        conversationThreadId: thread.id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          message: translate("Communication has been restored"),
          type: "success",
        }))
        refreshThreads()
      },
      onError: () => dispatch(pushToast({
        message: translate("There was an issue restoring this communication. Please try again!"),
        type: "error",
      })),
    }))
    onClose()
  }

  return (
    <>
      <IconButton
        id={ id }
        className="cp_unassigned-content-context-menu_icon-button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ onClose }
        className="cp_unassigned-content-context-menu_menu-component"
        transformOrigin={ {
          horizontal: "center",
          vertical: "top",
        } }
      >
        <MenuList
          dense={ true }
          className="menu-list"
        >
          <MenuItem
            className="menu-item"
            onClick={ onViewClick }
          >
            <ListItemText
              id="cp_unassigned-content-context-menu_view-menu-item"
              className="menu-item-label"
            >
              { translate("View") }
            </ListItemText>
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={ onAssignClick }
          >
            <ListItemText
              id="cp_unassigned-content-context-menu_assign-menu-item"
              className="menu-item-label"
            >
              { translate("Assign") }
            </ListItemText>
          </MenuItem>
          { !thread.archived
            ? (
              <MenuItem
                className="menu-item"
                onClick={ onArchiveClick }
              >
                <ListItemText
                  id="cp_unassigned-content-context-menu_archive-menu-item"
                  className="menu-item-label"
                >
                  { translate("Archive") }
                </ListItemText>
              </MenuItem>
            )
            : (
              <MenuItem
                className="menu-item"
                onClick={ onRestoreClick }
              >
                <ListItemText
                  id="cp_unassigned-content-context-menu_restore-menu-item"
                  className="menu-item-label"
                >
                  { translate("Restore") }
                </ListItemText>
              </MenuItem>
            )
          }
        </MenuList>
      </Menu>
    </>
  )
}
