import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import { getConversations, setSelectedConversation } from "../../../../../../state/unassignedThreadAssignModal"
import Input, { Props as InputProps } from "../../../../../Input"
import Autocomplete from "../../../../../Autocomplete"
import { isSuccess } from "../../../../../../util/apiClient"
import { ConversationFragment } from "../../../../../../graphql"

// Conversation Autocomplete is disabled until a social account is selected
export default function ConversationAutocomplete() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.UnassignedThreadAssignModal" })
  const dispatch = useDispatch()

  const {
    selectedSocialAccount,
    selectedConversation,
    conversations,
  } = useSelector(({ unassignedTreadAssignModal }) => unassignedTreadAssignModal)

  const [ options, setOptions ] = useState<ConversationFragment[]>([])

  const fetchCommunicationGroupOptions = () => {
    if (selectedSocialAccount) {
      dispatch(getConversations({
        communicationGroupNetworkAccountId: selectedSocialAccount.id,
      }))
    }
  }

  useEffect(() => {
    if (selectedSocialAccount) {
      fetchCommunicationGroupOptions()
    } else {
      dispatch(setSelectedConversation(null))
    }
  }, [ selectedSocialAccount ])

  useEffect(() => {
    if (isSuccess(conversations)) setOptions(conversations.payload.getConversations)
  }, [ conversations ])

  function getAutocompleteOptionLabel(
    option: string | ConversationFragment,
  ): string {
    if (typeof option === "string") return option
    return option.subject
  }

  function handleOnChange(
    conversation: string
    | ConversationFragment
    | (string | ConversationFragment)[]
    | null,
  ): void {
    if (
      conversation == null
      || typeof conversation === "string"
      || Array.isArray(conversation)
    ) {
      dispatch(setSelectedConversation(null))
      return
    }

    dispatch(setSelectedConversation(conversation))
  }

  return (
    <Autocomplete
      disabled={ !selectedSocialAccount }
      filterOptions={ (x) => x }
      isOptionEqualToValue={ ({ id }) => id === selectedConversation?.id }
      filterSelectedOptions={ true }
      getOptionLabel={ (o) => getAutocompleteOptionLabel(o) }
      options={ options }
      onChange={ (_, newValue) => handleOnChange(newValue) }
      value={ selectedConversation }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          fullWidth={ true }
          label={ translate("Conversation") }
          placeholder={ translate("Select Conversation") }
          value={ selectedConversation?.subject || "" }
        />
      ) }
    />

  )
}
