import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from "../../../../../Modal"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import {
  assignConversationThread,
  closeUnassignedThreadAssignModal,
  mergeConversationThread,
} from "../../../../../../state/unassignedThreadAssignModal"
import LoadingIndicator from "../../../../../LoadingIndicator"
import "./style.sass"
import CommunicationGroupAutocomplete from "./CommunicationGroupAutocomplete"
import SocialAccountAutocomplete from "./SocialAccountAutocomplete"
import ConversationAutocomplete from "./ConversationAutocomplete"
import { pushToast } from "../../../../../../state/toastSlice"
import { getUnassignedThreads } from "../../../../../../state/slidingPanelSlice/communications"

export default function UnassignedThreadAssignModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.UnassignedThreadAssignModal" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()

  const {
    open,
    unassignedThreadId,
    selectedCommunication,
    selectedSocialAccount,
    selectedConversation,
  } = useSelector(({ unassignedTreadAssignModal }) => unassignedTreadAssignModal)

  const [ submitLoading, setSubmitLoading ] = useState(false)

  const handleClose = () => {
    dispatch(closeUnassignedThreadAssignModal())
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)
    if (selectedConversation) {
      if (!unassignedThreadId) return
      await dispatch(mergeConversationThread({
        variables: {
          conversationId: selectedConversation.id,
          conversationThreadId: unassignedThreadId,
        },
        onSuccess: () => {
          dispatch(getUnassignedThreads({ startsWith: "" }))
          dispatch(pushToast({
            message: translate("Thread Merged"),
            additionalInfo: translate("The thread has been apprehended to the selected conversation."),
            type: "success",
          }))
        },
        onError: () => {
          dispatch(pushToast({
            message: tCommon("Error"),
            additionalInfo: tCommon("There was an error! Please try again."),
            type: "error",
          }))
        },
      }))
    } else {
      if (!selectedSocialAccount || !unassignedThreadId) return
      await dispatch(assignConversationThread({
        variables: {
          communicationGroupNetworkAccountId: selectedSocialAccount.id,
          conversationThreadId: unassignedThreadId,
        },
        onSuccess: () => {
          dispatch(getUnassignedThreads({ startsWith: "" }))
          dispatch(pushToast({
            message: translate("Thread Assigned"),
            additionalInfo: translate("The thread has been assigned to the selected communication group / social account."),
            type: "success",
          }))
        },
        onError: () => {
          dispatch(pushToast({
            message: tCommon("Error"),
            additionalInfo: tCommon("There was an error. Please try again!"),
            type: "error",
          }))
        },
      }))
    }
    setSubmitLoading(false)
    handleClose()
  }

  const submitDisabled = !selectedCommunication || !selectedSocialAccount

  return (
    <Modal
      className="cp_component_unassigned-thread-assign-modal"
      title={ translate("Assign To Group") }
      subtitle={ translate("If a conversation is not selected, a new one will be created.") }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Save") }
      secondaryLabel={ translate("Cancel") }
      open={ open }
      primaryAction={ handleSubmit }
      disabled={ submitDisabled }
      secondaryAction={ handleClose }
      onClose={ handleClose }
      closeAction={ handleClose }
    >
      <div className="autocomplete-container">
        <CommunicationGroupAutocomplete />
        <SocialAccountAutocomplete />
        <ConversationAutocomplete />
      </div>
    </Modal>
  )
}
