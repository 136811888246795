import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { debounce } from "@mui/material"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import { searchCommunications, setSelectedCommunication } from "../../../../../../state/unassignedThreadAssignModal"
import Input, { Props as InputProps } from "../../../../../Input"
import Autocomplete from "../../../../../Autocomplete"
import { isSuccess } from "../../../../../../util/apiClient"
import { CommunicationGroupRowFragment } from "../../../../../../graphql"
import * as Constant from "../../../../../../util/constant"

export default function CommunicationGroupAutocomplete() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.UnassignedThreadAssignModal" })
  const dispatch = useDispatch()

  const {
    communications,
    selectedCommunication,
  } = useSelector(({ unassignedTreadAssignModal }) => unassignedTreadAssignModal)

  const [ options, setOptions ] = useState<CommunicationGroupRowFragment[]>([])

  const fetchCommunicationGroupOptions = (input: string) => {
    dispatch(searchCommunications(input))
  }

  useEffect(() => {
    fetchCommunicationGroupOptions("")
  }, [])

  useEffect(() => {
    if (isSuccess(communications)) setOptions(communications.payload.searchCommunicationGroup.rows)
  }, [ communications ])

  function getAutocompleteOptionLabel(
    option: string | CommunicationGroupRowFragment,
  ): string {
    if (typeof option === "string") return option
    return option.name
  }

  function handleOnChange(
    communication: string
    | CommunicationGroupRowFragment
    | (string | CommunicationGroupRowFragment)[]
    | null,
  ): void {
    if (
      communication == null
      || typeof communication === "string"
      || Array.isArray(communication)
    ) {
      dispatch(setSelectedCommunication(null))
      return
    }

    dispatch(setSelectedCommunication(communication))
  }

  return (
    <Autocomplete
      filterOptions={ (x) => x }
      isOptionEqualToValue={ ({ id }) => id === selectedCommunication?.id }
      filterSelectedOptions={ true }
      getOptionLabel={ (o) => getAutocompleteOptionLabel(o) }
      options={ options }
      onInputChange={ debounce((_, newValue) => {
        fetchCommunicationGroupOptions(newValue)
      }, Constant.DEFAULT_DEBOUNCE_WAIT) }
      onChange={ (_, newValue) => handleOnChange(newValue) }
      value={ selectedCommunication }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          label={ `${ translate("Communication Group") }*` }
          placeholder={ translate("Select Communication Group") }
          value={ selectedCommunication?.name || "" }
        />
      ) }
    />

  )
}
