import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { RootState } from "../store"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import * as SearchHelper from "../../util/searchHelper"
import { scoreModalTitles } from "../../util/constant"

interface Count {
  count: number,
  value: number,
}

interface ScoreComponentInterface {
  raw: number;
  value: number;
  display: string;
  description: string;
  weight: number;
}

interface scoreBreakDown {
  counts: Count[] | [];
  selected: number | null;
  components: Array<ScoreComponentInterface>;
  title: string | null;
  network: string | null;
  scoreHistograms: Array<GraphQL.ValueCount>;
  histograms: GraphQL.ScoreHistogram[] | [],
}

const initialState: scoreBreakDown = {
  counts: [],
  selected: null,
  components: [],
  title: null,
  network: null,
  scoreHistograms: [],
  histograms: [],
}

interface rawScoreData {
  socialAccount: GraphQL.SocialAccount;
  scores: GraphQL.Score[];
}

export const scoreBreakDownSlice = createSlice({
  name: "scoreBreakDownSlice",
  initialState,
  reducers: {
    setScoreBreakDown: (
      state,
      action: PayloadAction<scoreBreakDown>,
    ) => action.payload,
    resetScoreBreakDown: (state) => ({
      ...initialState,
      histograms: state.histograms,
    }),
    setHistograms: (
      state,
      action: PayloadAction<GraphQL.ScoreHistogram[]>,
    ) => ({
      ...state,
      histograms: action.payload,
    }),
  },
})

// Export the actions and the reducer
export const {
  setScoreBreakDown, resetScoreBreakDown, setHistograms,
} = scoreBreakDownSlice.actions
export default scoreBreakDownSlice.reducer

//  Thunks
export const rawDataToScoreBreakDown = (
  scoreData: rawScoreData,
  scoreType: string,
  isAdCouncilScore?: boolean,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const {
    scoreBreakDown: {
      counts, scoreHistograms, histograms,
    },
  } = getState()
  const [ score ] = scoreData.scores.length === 1
    ? scoreData.scores
    : scoreData.scores.filter((scoreObj) => scoreObj.type === scoreType)
  let histrogramCounts: Count[] | [] = []
  const currHistogram = histograms.filter((hs) => (hs.network === scoreData.socialAccount.network && hs.type === scoreType))
  if (currHistogram.length) {
    histrogramCounts = currHistogram[0].histogram.map((item) => ({ count: item.count, value: item.value }))
  }
  const scoreBreakDownObj = {
    counts: histrogramCounts.length ? histrogramCounts : counts,
    selected: score.value,
    components: score.components,
    title: isAdCouncilScore ? scoreModalTitles.ADD_COUNCIL : (scoreModalTitles[score.type] || scoreModalTitles.Unknown),
    network: scoreData.socialAccount.network,
    scoreHistograms,
    histograms,
  }
  dispatch(setScoreBreakDown(scoreBreakDownObj))
}

export const fetchHistogramsData = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  const searchInit = SearchHelper.initialSearchState()
  const searchInput = JSON.parse(JSON.stringify(searchInit))
  const scoreHistogramsData = await API.fetchScoreHistogram(searchInput as GraphQL.GodSearchInput)
  let histograms: GraphQL.ScoreHistogram[] | [] = []
  if (API.isSuccess(scoreHistogramsData)) {
    const { searchInfluencerContent } = scoreHistogramsData.payload
    if (searchInfluencerContent && searchInfluencerContent.result) {
      const searchResults = searchInfluencerContent.result
      if (searchResults.__typename === "GodSearchSocialOutput") {
        histograms = searchResults.scoreHistograms
      }
    }
  }

  dispatch(setHistograms(histograms))
}

export const rawDataToScoreBreakDownForPublicList = (
  scoreData: rawScoreData,
  scoreType: string,
  listId: string,
  isAdCouncilScore?: boolean,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const scoreHistogramsData = await API.getPublicListScoreHistogram({ listId, socialAccountId: scoreData.socialAccount.id })

  let publicScoreHistograms : Array<GraphQL.ValueCount> = []

  if (API.isSuccess(scoreHistogramsData)) {
    const { publicSuggestionListSocialAccountByListCodeSocialAccountId } = scoreHistogramsData.payload
    if (publicSuggestionListSocialAccountByListCodeSocialAccountId && publicSuggestionListSocialAccountByListCodeSocialAccountId
      .scoreHistogram) {
      const searchResults = publicSuggestionListSocialAccountByListCodeSocialAccountId.scoreHistogram
      if (searchResults.__typename === "ScoreHistogram") {
        publicScoreHistograms = searchResults.histogram
      }
    }
  }

  const {
    scoreBreakDown: {
      counts, scoreHistograms, histograms,
    },
  } = getState()

  const [ score ] = scoreData.scores.length === 1
    ? scoreData.scores
    : scoreData.scores.filter((scoreObj) => scoreObj.type === scoreType)
  let histrogramCounts: Count[] | [] = []
  if (publicScoreHistograms.length) {
    histrogramCounts = publicScoreHistograms.map((item) => ({ count: item.count, value: item.value }))
  }

  const scoreBreakDownObj = {
    counts: histrogramCounts.length ? histrogramCounts : counts,
    selected: score.value,
    components: score.components,
    title: isAdCouncilScore ? scoreModalTitles.ADD_COUNCIL : (scoreModalTitles[score.type] || scoreModalTitles.Unknown),
    network: scoreData.socialAccount.network,
    scoreHistograms,
    histograms,
  }

  dispatch(setScoreBreakDown(scoreBreakDownObj))
}
