import React from "react"
import { useNavigate } from "react-router-dom"

import { useSelector } from "../../state/hooks"

import "./breadcrumb-header.sass"

type Props = {
  title: React.ReactNode
}

export default function BreadcrumbHeader({ title }: Props) {
  const { breadcrumbs } = useSelector(({ breadcrumbs: breadcrumbSlice }) => breadcrumbSlice)
  const navigate = useNavigate()

  return (
    <div className="cp-component_top-nav-title">
      { breadcrumbs.map((crumb) => (
        <>
          { /*
            eslint-disable-next-line jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */ }
          <h4
            className="cp_component_top-nav-title_breadcrumb-link"
            onClick={ () => {
              navigate(crumb.urlpath, { relative: "path" })
            } }
          >
            { crumb.header }
          </h4>
          <p>/</p>
        </>
      )) }
      <h4>
        { title }
      </h4>
    </div>
  )
}
