import React, { JSX, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { useDispatch } from "react-redux"
import SlidingPanel from "../../../SlidingPanel"
import "./communicationsSlidingPanel.sass"
import * as GraphQL from "../../../../graphql"
import Tabs, { TabLabelProps } from "../../../Tabs"
import CommunicationsContent from "./CommunicationsContent"
import TemplatesContent from "./TemplatesContent"
import { setCommunicationsTab } from "../../../../state/slidingPanelSlice"
import { useSelector } from "../../../../state/hooks"
import UnassignedContent from "./UnassignedContent"
import { Scope } from "../../../../util/types"

type Props = {
  open: boolean
  onClose: () => void
}

function CommunicationsSlidingPanel({
  open,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsTab" })
  const dispatch = useDispatch()

  const { communicationsTab } = useSelector(({ slidingPanels }) => slidingPanels)
  const { scopes } = useSelector(({ user }) => user)

  const tabs = useMemo(() => {
    const newTabs: TabLabelProps[] = [
      { label: translate("Groups") },
      { label: translate("Templates") },
    ]
    if (scopes.includes(Scope.COMMUNICATIONS_UNASSIGNED)) newTabs.push({ label: translate("Unassigned") })

    return newTabs
  }, [ scopes, translate ])

  const filterOptions = useMemo(() => [ {
    label: translate("Filter By"),
    values: [
      {
        label: translate("All"), value: null, keyId: uuidv4(),
      },
      {
        label: translate("Read Messages"), value: GraphQL.SearchCommunicationGroupFilter.Read, keyId: uuidv4(),
      },
      {
        label: translate("Unread Messages"), value: GraphQL.SearchCommunicationGroupFilter.Unread, keyId: uuidv4(),
      },
      {
        label: translate("Notifications On"), value: GraphQL.SearchCommunicationGroupFilter.Subscribed, keyId: uuidv4(),
      },
      {
        label: translate("Notifications Off"), value: GraphQL.SearchCommunicationGroupFilter.Unsubscribed, keyId: uuidv4(),
      } ],
  } ], [ translate ])

  const tabContent = useMemo(() => {
    switch (communicationsTab) {
      case 0:
        return (
          <CommunicationsContent
            filterOptions={ filterOptions }
            onClose={ onClose }
          />
        )
      case 1:
        return <TemplatesContent />
      case 2:
        return <UnassignedContent />

      default:
        return null
    }
  }, [ communicationsTab ])

  return (
    <SlidingPanel
      className="cp_component_navigation-bar-comm-sliding-panel"
      title={ translate("Communications") }
      open={ open }
      onClose={ onClose }
      disablePortal={ true }
    >
      <Tabs
        className="cp_component_navigation-bar-comm-tabs"
        tabs={ tabs }
        handleChange={ (index) => dispatch(setCommunicationsTab(index)) }
        defaultValue={ communicationsTab }
      />

      { tabContent }
    </SlidingPanel>
  )
}

export default CommunicationsSlidingPanel
