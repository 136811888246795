import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import "../../Audience/index.sass"
import { useParams } from "react-router-dom"

import Divider from "../../../Divider"
import { useDispatch, useSelector } from "../../../../state/hooks"
import * as GraphQL from "../../../../graphql"
import { fetchAudience } from "../../../../state/publicListSlice"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import FollowersChartWrapper from "./FollowersChartWrapper"
import GenderChartWrapper from "./GenderChartWrapper"
import MaritalChartWrapper from "./MaritalChartWrapper"
import FamilyChartWrapper from "./FamilyChartWrapper"
import BasicChartWrapper from "./BasicChartWrapper"
import EthnicityChartWrapper from "./EthnicityChartWrapper"
import ReligionsChartWrapper from "./ReligionsChartWrapper"
import VocationWrapper from "./VocationWrapper"
import LocationWrapper from "./LocationWrapper"
import AffinitiesWrapper from "./AffinitiesWrapper"
import LanguagesChartWrapper from "./LanguagesChartWrapper"
import { ListTogglesType } from "../../../../util/types"
import { getAudienceQualityScores } from "../../../../state/socialProfileSlice"
import AudienceQualityChartWrapper from "../../SocialProfileTabs/AccountAudienceDetails/AudienceQualityChartWrapper"

type Props = {
  toggles: ListTogglesType
}

export default function AccountAudienceDetails({ toggles }: Props) {
  const { audience, selectedListSocialAccount } = useSelector(({ publicList }) => publicList)
  const audienceQualityScore = useSelector(({ socialProfile }) => socialProfile.audienceQualityScore)
  const { t: translate } = useTranslation([], { keyPrefix: "component.AudienceDetails" })
  const dispatch = useDispatch()
  const { listCode } = useParams()

  useEffect(() => {
    if (!selectedListSocialAccount || !listCode) return
    dispatch(fetchAudience({
      code: listCode,
      socialAccountId: selectedListSocialAccount.socialAccount.id,
    }))
    if (audienceQualityScore === "init") {
      dispatch(getAudienceQualityScores(selectedListSocialAccount.socialAccount.id))
    }
  }, [ selectedListSocialAccount, audienceQualityScore ])

  const socialAccount = useMemo(
    () => selectedListSocialAccount
      ? selectedListSocialAccount.socialAccount
      : null,
    [ selectedListSocialAccount ],
  )

  const showAudienceQuality = useMemo(
    () => socialAccount && socialAccount.network === GraphQL.Network.Instagram,
    [ socialAccount ],
  )

  return (
    <div className="cp_audience-details_component">
      { toggles.displayFollowersOverTime && (
        <div className="cp_audience-details_component-widget">
          <FollowersChartWrapper />
        </div>
      ) }
      { (showAudienceQuality && toggles.displayAudienceQuality) && (
      <AudienceQualityChartWrapper
        socialAccount={ socialAccount }
        id="cp_audience_quality_charts"
        className="cp_audience-details_component-widget"
      />
      ) }
      <div className="cp_audience-details_component-row">
        { toggles.displayGender && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-1">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Gender") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <GenderChartWrapper />
          </div>
        </div>
        ) }
        { toggles.displayFamilyStatus && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-2">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Family") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            { audience === "init" || audience === "loading"
              ? <LoadingIndicatorCard />
              : (
                <>
                  <MaritalChartWrapper />
                  <FamilyChartWrapper />
                </>
              )
            }
          </div>
        </div>
        ) }
      </div>
      { (toggles.displayAgeRange || toggles.displayIncomeRange || toggles.displayEducationLevel) && (
      <div className="cp_audience-details_component-widget">
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Basic") }
        </h6>
        <Divider />
        <div className="cp_audience-details_component-charts">
          <BasicChartWrapper
            displayAgeRange={ toggles.displayAgeRange }
            displayIncomeRange={ toggles.displayIncomeRange }
            displayEducationLevel={ toggles.displayEducationLevel }
          />
        </div>
      </div>
      ) }
      <div className="cp_audience-details_component-row">
        { toggles.displayEthnicities && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Ethnicity") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <EthnicityChartWrapper />
          </div>
        </div>
        ) }
        { toggles.displayLanguages && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Languages") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <LanguagesChartWrapper />
          </div>
        </div>
        ) }
        { toggles.displayReligions && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Religion") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <ReligionsChartWrapper />
          </div>
        </div>
        ) }
      </div>
      { (toggles.displayCities || toggles.displayCountries || toggles.displayStates) && (
      <div className="cp_audience-details_component-widget">
        <div className="cp_audience-details_component">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Location") }
          </h6>
          <LocationWrapper
            displayCities={ toggles.displayCities }
            displayCountries={ toggles.displayCountries }
            displayStates={ toggles.displayStates }
          />
        </div>
      </div>
      )
        }

      { (toggles.displayOccupations || toggles.displayIndustries || toggles.displayEmployers || toggles.displayUniversities) && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <VocationWrapper
              displayOccupations={ toggles.displayOccupations }
              displayIndustries={ toggles.displayIndustries }
              displayEmployers={ toggles.displayEmployers }
              displayUniversities={ toggles.displayUniversities }
            />
          </div>
        </div>
      ) }
      { toggles.displayAffinities && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <AffinitiesWrapper />
          </div>
        </div>
      ) }
    </div>
  )
}
