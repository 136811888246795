import React from "react"
import { useTranslation } from "react-i18next"
import "./communications-sidebar.sass"
import {
  DraftsOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  GroupsOutlined,
  SendOutlined,
} from "@mui/icons-material"
import { Typography } from "@mui/material"
import Button from "../Button"
import Divider from "../Divider"

export default function CommunicationsSidebar() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsSidebar" })

  const handleCreateClick = () => {
    // Placeholder
  }

  return (
    <div className="cp_component_communications-sidebar">
      <Button
        className="create-message-button"
        label={ translate("Create Message") }
        isPrimary={ false }
        onClick={ handleCreateClick }
      />
      <div className="inbox-category-label">
        <FolderOpenOutlined />
        <p>{ translate("Inbox") }</p>
      </div>
      <div className="inbox-category-label">
        <SendOutlined />
        <p>{ translate("Sent") }</p>
      </div>
      <div className="inbox-category-label">
        <FolderOutlined />
        <p>{ translate("Batch") }</p>
      </div>
      <div className="inbox-category-label">
        <DraftsOutlined />
        <p>{ translate("Drafts") }</p>
      </div>
      <Divider />

      <div className="inbox-category-label">
        <GroupsOutlined />
        <Typography>{ translate("Group Accounts") }</Typography>
      </div>

    </div>
  )
}
